<template>
    <div ref="submitReceiptDialog" class="modal fade" data-bs-backdrop="static" id="submitReceiptDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Payment Transaction Number</h5>
                    <button type="button" class="btn-close" @click="closeReceiptDialog()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating fs-top mb-3">
                            <input id="transNumber" type="text" class="form-control" placeholder="TransactionNumber"
                                v-model="transNumber" />
                            <label for="transNumber">Transaction Number</label>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeReceiptDialog()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" @click="sendSms()">
                        Send
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { mapActions } from 'vuex';

export default {
    name: "TransactionNumberEntryDialog",
    components: {
    },
    setup() {

    },
    data() {
        return {
            submitReceiptDialog: null,
            transNumber: null
        };
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        },
        payment: {
            type: Object,
            required: true
        },
        retailerId: {
            type: String,
            required: true
        }
    },
    watch: {
        showHide: function (newVal) {
            if (newVal) {
                this.submitReceiptDialog.show();
            } else {
                this.submitReceiptDialog.hide();
            }
        }
    },
    methods: {
        ...mapActions(['sendRetailerPaymentSMS']),
        async sendSms() {
            let response = await this.sendRetailerPaymentSMS({
                data: {
                    retailerId: this.retailerId,
                    transactionNumber: this.transNumber,
                    paymentDate: this.payment.paymentDate,
                }
            })
            console.log(response)


            this.transNumber = null;

            this.$emit('smsSent', {
                success: response.success
            });

        },

        closeReceiptDialog() {
            this.transNumber = null;
            this.$emit('closed')
        },
    },
    async mounted() {
        this.submitReceiptDialog = new Modal(this.$refs.submitReceiptDialog, {});
        if (this.showHide) {
            this.submitReceiptDialog.show();
        }
    },
}
</script>
<style scoped>

</style>