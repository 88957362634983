<template>
  <base-layout>
    <div class="container terms-container">
      <h5>Farmserv Input Partners</h5>
      <div class="card mt-2 mb-2" v-for="(retailer) in retailers" :key="retailer">
        <div class="card-body">
          <dl class="row">
            <dt class="col-4">Name</dt>
            <dd class="col-8">{{ retailer.firstName }} {{ retailer.lastName }}</dd>

            <dt class="col-4">Company Name</dt>
            <dd class="col-8">{{ retailer.entityName }}</dd>

            <dt class="col-4">Address</dt>
            <dd class="col-8">{{ retailer.address }}</dd>

            <dt class="col-4">Taluka</dt>
            <dd class="col-8">{{ retailer.taluka }}</dd>

            <dt class="col-4">District</dt>
            <dd class="col-8">{{ retailer.district }}</dd>

            <dt class="col-4">State</dt>
            <dd class="col-8">{{ retailer.state }}</dd>

            <dt class="col-4">Phone</dt>
            <dd class="col-8">{{ retailer.phone }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import baseLayout from "@/layouts/base.vue";
import { mapActions } from "vuex";

export default {
  name: "RetailersList",
  components: {
    baseLayout,
  },
  data() {
    return {
      retailers: []
    };
  },
  computed: {
  },
  watch: {

  },
  methods: {
    ...mapActions(['getRetailersList']),
  },
  async mounted() {
    console.log("Calling Terms")
    const response = await this.getRetailersList()
    console.log(response.data)
    if (response.success) {
      this.retailers = response.data.retailers;
    }
  },
};
</script>

<style scoped>
.terms-container {
  margin-top: 30px;
  padding-bottom: 30px;
  max-width: 700px;
}
</style>