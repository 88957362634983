<template>
  <admin-layout>
    <div
      class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      "
    >
      <h1 class="h2">Settings</h1>
    </div>

    <div>
      <form>
        <div class="form-floating fs-top mb-3">
          <input
            id="amount"
            type="text"
            class="form-control"
            placeholder="creditLimit"
            v-model="creditLimit"
          />
          <label for="creditLimit">Membership Limit</label>
        </div>
        <div>
          <button
              type="button"
              class="btn btn-secondary"
              @click="resetSettings()"
            >
              Reset
            </button>
            <button
              type="button"
              style="margin-left: 15px;"
              class="btn btn-primary"
              @click="updateCreditLimit()"
            >        
            Save  
            </button>
        </div>
      </form>
    </div>
  </admin-layout>
</template>

<script>
import adminLayout from "@/layouts/admin.vue";
import { useToast } from "vue-toastification";
import { mapActions, mapState } from "vuex";

export default {
  name: "SettingsView",
  components: {
    adminLayout,
  },
  setup() {
    return {
      toast: useToast()
    }
  },
  data() {
    return {
      creditLimit: null,
    };
  },
  computed: {
    ...mapState(["settings"])
  },
  methods: {
    ...mapActions(["getSettings", "updateSettings"]),
    async updateCreditLimit() {
      let response = await this.updateSettings({
        data: {
          name: 'farmer_credit_limit',
          value: this.creditLimit
        }
      })
      if (response.success) {
        this.toast.success("Update successful")
        this.getSettings();
      }
    },
    async resetSettings() {
      if (this.settings != null) {
        let filtered = this.settings.filter(x => x.name === 'farmer_credit_limit')
        if (filtered && filtered.lenght != 0)
          this.creditLimit = filtered[0].value
      }
    }
  },
  async mounted() {
    let response = await this.getSettings();
    if (response.success) {

      if (this.settings != null) {
        let filtered = this.settings.filter(x => x.name === 'farmer_credit_limit')
        if (filtered && filtered.lenght != 0)
          this.creditLimit = filtered[0].value
      }
    }
  },

};
</script>

<style scoped>
</style>