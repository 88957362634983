<template>
  <farmer-layout>
    <div class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      ">
      <h1 class="h2">Farmer Dashboard</h1>
      <div class="btn-toolbar mb-2 mb-md-0"></div>
    </div>

    <div class="card text-center mb-3">
      <div class="card-header">Orders</div>
      <div class="card-body">
        <p class="card-text">
          View All Orders
        </p>
        <router-link :to="{ name: 'farmerTransactions', params: { fid: uid }}" class="btn btn-primary">Continue
        </router-link>
      </div>
    </div>

  </farmer-layout>
</template>

<script>
import farmerLayout from "@/layouts/farmer.vue";
import { mapState } from 'vuex';
import { useToast } from "vue-toastification";


export default {
  name: "FarmerDashboardView",
  setup() {
    return {
      toast: useToast()
    }
  },
  components: {
    farmerLayout,
  },
  data() {
    return {
      showHide: false
    };
  },
  computed: {
    ...mapState(["uid"])
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style scoped>

</style>