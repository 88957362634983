<template>
    <div>
        <top-navbar-component />
        <div class="container-fluid">
            <div class="row">
                <main class="col-md-12 ms-sm-auto col-lg-12 px-md-4">
                    <slot />
                    <h1 class="h2">
                        <slot name="heading" />
                    </h1>
                    <slot name="content" />
                </main>
            </div>
        </div>
    </div>
</template>
<script>
import topNavbarComponent from "@/components/TopNavBar";

export default ({
    name: "FarmerLayout",
    data() {
        return {};
    },
    components: {
        topNavbarComponent,
    },
})
</script>
<style scoped>

</style>
