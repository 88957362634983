<template>
    <div ref="submitReceiptDialog" class="modal fade" data-bs-backdrop="static" id="submitReceiptDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Payment Receipt</h5>
                    <button type="button" class="btn-close" @click="closeReceiptDialog()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating fs-top mb-3">
                            <input id="amount" type="text" class="form-control" placeholder="amount"
                                v-model="data.receiptAmount" />
                            <label for="amount">Amount</label>
                        </div>
                        <div class="form-floating fs-top mb-3">
                            <datepicker id="receiptDate" class="form-control" v-model="data.receiptDate"
                                :clearable="false" placeholder="Receipt Date" format="dd-MM-YYYY" />
                        </div>
                        <div class="form-floating fs-middle mb-3">
                            <select class="form-select" id="receiptType" aria-label="taluka" v-model="data.receiptType">
                                <option selected value="NA">Receipt Type</option>
                                <option v-for="receiptType in receiptTypes" :value="receiptType" :key="receiptType">
                                    {{receiptType}}
                                </option>
                            </select>
                            <label for="receiptType">Receipt Type</label>
                        </div>
                        <div class="form-floating fs-top mb-3">
                            <textarea class="form-control" placeholder="Note" v-model="data.note" id="note"
                                maxlength="500"></textarea>
                            <label for="note">Note</label>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeReceiptDialog()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" @click="submitReceipt()">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { mapActions } from 'vuex';
import Datepicker from "vue3-datepicker";

export default {
    name: "ReceiptEntryDialog",
    components: {
        Datepicker,
    },
    setup() {

    },
    data() {
        return {
            submitReceiptDialog: null,
            receiptTypes: ['ORDER', 'MEMBERSHIP'],
            data: {
                receiptAmount: null,
                receiptDate: null,
                receiptType: null,
                note: null
            }
        };
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            required: true
        }
    },
    watch: {
        showHide: function (newVal) {
            if (newVal) {
                this.submitReceiptDialog.show();
            } else {
                this.submitReceiptDialog.hide();
            }
        }
    },
    methods: {
        ...mapActions(['createReceipt']),
        async submitReceipt() {
            console.log(this.user)
            let success = false;

            this.data.farmerId = this.user.uid
            let response = await this.createReceipt(this.data)
            success = response.success
            this.data.receiptAmount = this.data.receiptDate = this.receiptType = this.data.note = null

            this.$emit('receiptSubmitted', {
                success
            });
        },
        closeReceiptDialog() {
            this.data.receiptAmount = this.data.receiptDate = this.receiptType = this.data.note = null
            this.$emit('closed')
        },
    },
    async mounted() {
        this.submitReceiptDialog = new Modal(this.$refs.submitReceiptDialog, {});
        if (this.showHide) {
            this.submitReceiptDialog.show();
        }
    },
}
</script>
<style scoped>

</style>