<template>
  <admin-layout>
    <div
      class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      "
    >
      <h1 class="h2">Transaction Master</h1>

      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">
          <button
            type="button"
            class="btn btn-sm btn-outline-secondary"
            @click="downloadReport()" :disabled="orders.length === 0"
          >
            Download
          </button>
        </div>
      </div>
    </div>
    <div>
      <div class="table-responsive">
        <table class="table table-striped" v-if="orders?.length > 0">
          <thead>
            <tr>
              <th scope="col">OrderId</th>
              <th scope="col">Date</th>
              <th scope="col">Farmer</th>
              <th scope="col">Farmer Phone</th>
              <th scope="col">Retailer</th>
              <th scope="col">Retailer Phone</th>
              <th scope="col">Item Name</th>
              <th scope="col">Pack Size</th>
              <th scope="col">Quantity</th>
              <th scope="col">Rate (including GST)</th>
              <th scope="col">Amount</th>
              <th scope="col">GST</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="order in orders" :key="order">
              <template v-for="item in order.items" :key="item">
                <tr>
                  <td>{{ order.orderId }}</td>
                  <td>{{ order.orderTime }}</td>
                  <td>
                    {{ order.farmer?.firstName }} {{ order.farmer?.lastName }}
                  </td>
                  <td>{{ order.farmer?.phone }}</td>
                  <td>
                    {{ order.retailer?.firstName }}
                    {{ order.retailer?.firstName }}
                  </td>
                  <td>{{ order.retailer?.phone }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.packSize }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.ratePerPack }}</td>
                  <td>{{ item.totalValue }}</td>
                  <td>{{ item.gst }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </admin-layout>
</template>

<script>
import adminLayout from "@/layouts/admin.vue";
import { mapActions } from "vuex";

export default {
  name: "TransactionMasterView",
  components: {
    adminLayout,
  },
  data() {
    return {
      orders: [],
    };
  },
  methods: {
    ...mapActions([
      "getTransactionMasterByDate",
      "downloadTransactionMasterByDate",
    ]),
    async downloadReport() {
      let response = await this.downloadTransactionMasterByDate({});
      if (response.success) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", "masterTransaction.csv");
        document.body.appendChild(link);
        link.click();
      }
    },
  },
  async mounted() {
    let response = await this.getTransactionMasterByDate({});
    if (response.success) {
      this.orders = response.data.orders;
    }
  },
};
</script>

<style scoped>
.item-key {
  min-width: 200px;
}

.item-value {
  min-width: 400px;
}
</style>