import axios from 'axios'
import store from '@/store'
import router from '@/router';
import NProgress from 'nprogress';

NProgress.configure({
    showSpinner: false
})
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
})

service.interceptors.request.use((config) => {
    NProgress.start()
    if (store.state.loggedUser) {
        const token = store.state.token;
        config.headers = {
            Authorization: `Bearer ${token}`
        };
    }
    return config;
});

service.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            store.dispatch('logout')
            router.push({ name: "login" })
            store.state.$bus?.emit('errorOccurred', error.response.data)
            return Promise.reject(error)
        } else if ([400, 500].includes(error.response.status)) {
            store.state.$bus?.emit('errorOccurred', error.response.data)
            return Promise.resolve(error)
        } else {
            return Promise.resolve(error)
        }
    }
);

service.interceptors.response.use(response => {
    NProgress.done()
    return response
})


// Request Interceptor
// service.interceptors.request.use(config => {
//     store.dispatch('displayLoader', true)

//     return config
// }, error => {
//     store.dispatch('displayLoader', false)

//     return Promise.reject(error)
// })

// Response Interceptor
// service.interceptors.response.use(response => {
//     store.dispatch('displayLoader', false)

//     return response
// }, error => {
//     store.dispatch('displayLoader', false)

//     var errors = error

//     if (error.response) {
//         // Session Expired
//         if (401 === error.response.status) {
//             errors = error.response.data.message
//             store.dispatch('logOut')
//         }

//         // Errors from backend
//         if (error.response.status == 422) {
//             errors = ''
//             for (var errorKey in error.response.data.errors) {
//                 errors += error.response.data.errors[errorKey].detail + '<br>'
//             }
//         }

//         // Backend error
//         if (500 === error.response.status) {
//             errors = error.response.data.message
//         }

//         // Server down
//         if (503 === error.response.status) {
//             errors = error.response.data.message
//         }

//         // 404
//         if (error.response.status == 404) {
//             errors = 'Page not found!'
//         }
//     }

//     Vue.notify({
//         group: 'notify',
//         type: 'error',
//         title: 'Error',
//         text: String(errors),
//     })

//     return Promise.reject(error)
// })

// Export axios
export default service