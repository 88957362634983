<template>
    <div ref="editRetailerDialog" class="modal fade" data-bs-backdrop="static" id="editRetailerDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Input Partner</h5>
                    <button type="button" class="btn-close" @click="closeRetailer()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating fs-top mb-3">
                            <input id="fname" type="text" class="form-control" placeholder="FirstName"
                                v-model="newTrader.firstName" @focus="v$.newTrader.firstName.$reset()" />
                            <label for="fname">First Name</label>
                            <div v-if="v$.newTrader.firstName.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.firstName.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <input id="lname" type="text" class="form-control" placeholder="LastName"
                                v-model="newTrader.lastName" @focus="v$.newTrader.lastName.$reset()" />
                            <label for="lname">Last Name</label>
                            <div v-if="v$.newTrader.lastName.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.lastName.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <input id="entityName" type="text" class="form-control" placeholder="Entity"
                                v-model="newTrader.entityName" @focus="v$.newTrader.entityName.$reset()" />
                            <label for="entityName">Entity Name</label>
                            <div v-if="v$.newTrader.entityName.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.entityName.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <input id="gstNo" type="text" class="form-control" placeholder="GST Number" v-model="newTrader.gstNo"
                                @focus="v$.newTrader.gstNo.$reset()" maxlength="15" />
                            <label for="gstNo">GST Number</label>
                            <div v-if="v$.newTrader.gstNo.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.gstNo.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>
                        
                        <div class="form-floating fs-middle mb-3">
                            <input id="pesticideLicenseNo" type="text" class="form-control" placeholder="Pesticide License Number"
                                v-model="newTrader.pesticideLicenseNo" @focus="v$.newTrader.pesticideLicenseNo.$reset()" maxlength="15" />
                            <label for="pesticideLicenseNo">Pesticide License Number</label>
                            <div v-if="v$.newTrader.pesticideLicenseNo.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.pesticideLicenseNo.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>
                        
                        <div class="form-floating fs-middle mb-3">
                            <input id="seedLicenseNo" type="text" class="form-control" placeholder="Seed License Number"
                                v-model="newTrader.seedLicenseNo" @focus="v$.newTrader.seedLicenseNo.$reset()" maxlength="15" />
                            <label for="seedLicenseNo">Seed License Number</label>
                            <div v-if="v$.newTrader.seedLicenseNo.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.seedLicenseNo.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <input id="phone" type="text" class="form-control" placeholder="9889898898"
                                v-model="newTrader.phone" @focus="v$.newTrader.phone.$reset()" maxlength="10" />
                            <label for="phone">Phone Number</label>
                            <div v-if="v$.newTrader.phone.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.phone.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <input id="address" type="text" class="form-control" placeholder="Address"
                                v-model="newTrader.address" @focus="v$.newTrader.address.$reset()" />
                            <label for="address">Address</label>
                            <div v-if="v$.newTrader.address.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.address.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <select class="form-select" id="state" aria-label="State" v-model="newTrader.state"
                                :disabled="states === {}" @change="stateSelected">
                                <option selected value="NA">Select State</option>
                                <option v-for="state in Object.keys(states)" :value="state" :key="state">{{state}}
                                </option>
                            </select>
                            <label for="state">State</label>
                            <div v-if="v$.newTrader.state.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.state.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <select class="form-select" id="district" aria-label="district" v-model="newTrader.district"
                                :disabled="newTrader.state === 'NA'" @change="districtSelected">
                                <option selected value="NA">Select District</option>
                                <option v-for="district in districts" :value="district" :key="district">
                                    {{district}}
                                </option>
                            </select>
                            <label for="district">District</label>
                            <div v-if="v$.newTrader.district.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.district.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <select class="form-select" id="taluka" aria-label="taluka" v-model="newTrader.taluka"
                                :disabled="newTrader.district === 'NA'">
                                <option selected value="NA">Select Taluka</option>
                                <option v-for="taluka in talukas" :value="taluka" :key="taluka">
                                    {{taluka}}
                                </option>
                            </select>
                            <label for="taluka">Taluka</label>
                            <div v-if="v$.newTrader.taluka.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.taluka.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="form-floating fs-bottom mb-3">
                            <input id="pincode" type="text" class="form-control" placeholder="Pincode"
                                v-model="newTrader.pincode" @focus="v$.newTrader.pincode.$reset()" maxlength="6" />
                            <label for="pincode">Pincode</label>
                            <div v-if="v$.newTrader.pincode.$error" class="error-message mb-2">
                                <p v-for="error of v$.newTrader.pincode.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeRetailer()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" @click="saveRetailer()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { helpers, required, numeric, minLength, alphaNum, maxLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mapActions, mapState } from "vuex";

const locationSelection = (value) => value !== "NA"

export default {
    name: "EditRetailerDialog",
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            editRetailerDialog: null,
            districts: [],
            talukas: [],
            newTrader: {},
        };
    },
    validations() {
        return {
            newTrader: {
                firstName: { required },
                lastName: { required },
                entityName: { required },
                gstNo: { required, alphaNum, minLength: minLength(15), maxlength: maxLength(15) },
                pesticideLicenseNo: { required, alphaNum, },
                seedLicenseNo: { required, alphaNum, },
                address: { required },
                phone: { required, numeric, minLength: minLength(10) },
                pincode: { required, numeric, minLength: minLength(6) },
                district: { locationSelection: helpers.withMessage('Value is required', locationSelection) },
                state: { locationSelection: helpers.withMessage('Value is required', locationSelection) },
                taluka: { locationSelection: helpers.withMessage('Value is required', locationSelection) }
            },
        }
    },
    computed: {
        ...mapState(["states"]),
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        },
        retailer: {
            type: Object,
            required: true
        }
    },
    watch: {
        showHide: function (newVal, oldVal) {
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            if (newVal) {
                this.editRetailerDialog.show();
            } else {
                this.editRetailerDialog.hide();
            }
        },
        retailer: function (val) {
            console.log(val)
            if (val !== null) {
                this.newTrader = { ...val }
                this.districts = Object.keys(this.states[this.newTrader.state].districts)
                this.talukas = this.states[this.newTrader.state].districts[this.newTrader.district].talukas.map((value) => {
                    return value.name;
                })
            }
        }          
    },
    methods: {
        ...mapActions(["updateRetailer", "getStates"]),
        stateSelected() {
            if (this.newTrader.state === "NA") {

                this.newTrader.district = "NA"
                this.newTrader.taluka = "NA"
                this.districts = []
                this.talukas = []
                return;
            } else {
                this.districts = Object.keys(this.states[this.newTrader.state].districts)
            }
        },
        districtSelected() {
            if (this.newTrader.district === "NA") {
                this.talukas = []
                this.newTrader.taluka = "NA"
                return;
            } else {
                this.talukas = this.states[this.newTrader.state].districts[this.newTrader.district].talukas.map((value) => {
                    return value.name;
                })
            }
        },
        closeRetailer() {
            this.v$.$reset()
            this.$emit('closed')
        },
        async saveRetailer() {
            const result = await this.v$.$validate()

            if (result) {
                let createResult = await this.updateRetailer({
                    retailerId: this.newTrader.uid,
                    data: this.newTrader
                });
                console.log(createResult)
                if (createResult.success) {
                    this.$emit('updated')
                }
            }
        },
    },
    async mounted() {
        this.editRetailerDialog = new Modal(this.$refs.editRetailerDialog, {});
        if (this.showHide) {
            this.editRetailerDialog.show();
        }
        if (Object.keys(this.states).length === 0) {
            await this.getStates({});
        }
    },
}
</script>
<style scoped>

</style>