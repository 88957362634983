<template>
    <div ref="addUserDialog" class="modal fade" data-bs-backdrop="static" id="addUserDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Payment</h5>
                    <button type="button" class="btn-close" @click="closeDialog()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating fs-middle mb-3">
                            <input id="phone" type="text" class="form-control" placeholder="9889898898"
                                v-model="data.phone" @focus="v$.data.phone.$reset()" maxlength="10" />
                            <label for="phone">Phone Number</label>
                            <div v-if="v$.data.phone.$error" class="error-message mb-2">
                                <p v-for="error of v$.data.phone.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="form-floating fs-middle mb-3">
                            <select class="form-select" id="userType" aria-label="State" v-model="data.userType">
                                <option selected value="NA">Select Type</option>
                                <option v-for="userType in userTypes" :value="userType" :key="userType">{{ userType }}
                                </option>
                            </select>
                            <label for="userType">User Type</label>
                            <div v-if="v$.data.userType.$error" class="error-message mb-2">
                                <p v-for="error of v$.data.userType.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div v-if="otpEnabled" class="form-floating fs-bottom mb-3">
                            <input id="otp" type="text" class="form-control" placeholder="otp" v-model="otp"
                                maxlength="6" />
                            <label for="otp">OTP</label>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeDialog()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" @click="getOtp()" v-if="!otpEnabled">
                        Get OTP
                    </button>
                    <button type="button" class="btn btn-primary" @click="sendOtp()" v-if="otpEnabled">
                        Send OTP
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, numeric, minLength } from '@vuelidate/validators'

const userTypeSelection = (value) => value !== "NA"

export default {
    name: "AddUserDialog",
    components: {
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            addUserDialog: null,
            data: {
                phone: null,
                userType: null,
            },
            otp: null,
            otpEnabled: false,
            userTypes: ['ADMIN', 'SUPERADMIN']
        };
    },
    validations() {
        return {
            data: {
                phone: { required, numeric, minLength: minLength(10) },
                userType: { required, userTypeSelection: helpers.withMessage('Value is required', userTypeSelection) },
            }
        }
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        showHide: function (newVal) {
            if (newVal) {
                this.addUserDialog.show();
            } else {
                this.addUserDialog.hide();
            }
        }
    },
    methods: {
        ...mapActions(['userRegStep1', 'userRegStep2']),
        async getOtp() {
            const result = await this.v$.$validate()

            if (result) {
                let response = await this.userRegStep1(this.data)

                if (response.success) {
                    this.otpEnabled = true;
                }
            }
        },
        async sendOtp() {
            console.log(this.user)
            let response = await this.userRegStep2({
                phone: this.data.phone,
                otp: this.otp
            })

            if (response.success) {
                this.data.phone = this.data.userType = this.otp = null
                this.otpEnabled = false;
                this.$emit('userCreated', { success: response.success })
            }
        },
        closeDialog() {
            this.$emit('userClosed')
            this.data.phone = this.data.userType = this.otp = null
            this.otpEnabled = false;
        },
    },
    async mounted() {
        this.addUserDialog = new Modal(this.$refs.addUserDialog, {});
        if (this.showHide) {
            this.addUserDialog.show();
        }
    },
}
</script>
<style scoped>

</style>