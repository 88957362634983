<template>
  <div class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
    <router-link class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6"
      v-if="userType === 'ADMIN' || userType === 'SUPERADMIN'"
      :to="{ name: 'dashboard' }" v-slot="{ navigate }">
      <div @click="navigate">
        <span class="fs-logo">FarmServ </span>
        <span class="fs-product-logo">Infinity</span>
      </div>
    </router-link>
    <router-link class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" v-if="userType === 'RETAILER'"
      :to="{ name: 'retailerDashboard' }" v-slot="{ navigate }">
      <div @click="navigate">
        <span class="fs-logo">FarmServ </span>
        <span class="fs-product-logo">Infinity</span>
      </div>
    </router-link>
    <router-link class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" v-if="userType === 'TRADER'"
      :to="{ name: 'traderDashboard' }" v-slot="{ navigate }">
      <div @click="navigate">
        <span class="fs-logo">FarmServ </span>
        <span class="fs-product-logo">Infinity</span>
      </div>
    </router-link>
    <router-link class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" v-if="userType === 'FARMER'"
      :to="{ name: 'farmerDashboard' }" v-slot="{ navigate }">
      <div @click="navigate">
        <span class="fs-logo">FarmServ </span>
        <span class="fs-product-logo">Infinity</span>
      </div>
    </router-link>
    <div class="navbar-nav" style="flex-direction: row !important">
      <div class="nav-item text-nowrap">
        <router-link class="nav-link" v-if="userType === 'ADMIN'" :to="{ name: 'dashboard' }">
          Home
        </router-link>
        <router-link class="nav-link" v-if="userType === 'RETAILER'" :to="{ name: 'retailerDashboard' }">
          Home
        </router-link>
        <router-link class="nav-link" v-if="userType === 'TRADER'" :to="{ name: 'traderDashboard' }">
          Home
        </router-link>
        <router-link class="nav-link" v-if="userType === 'FARMER'" :to="{ name: 'farmerDashboard' }">
          Home
        </router-link>
      </div>
      <div class="nav-item text-nowrap">
        <a href="#" class="nav-link px-3" @click.prevent="logoutUser">Sign out</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'TopNavBar',
  computed: {
    ...mapState(["loggedUser", "userType"]),
  },
  watch: {
    loggedUser(newValue) {
      console.log("Watch loggedUser called");
      if (newValue == null) {
        this.$router.push({ name: "login" });
      }
    }
  },
  methods: {
    ...mapActions(["logout"]),
    logoutUser() {
      this.logout();
      this.$router.push({ name: 'login' });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.fs-logo {
  font-size: 16px;
  font-weight: 300;
}

.fs-product-logo {
  font-size: 16px;
  font-weight: 700;
}
</style>
