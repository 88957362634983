<template>
    <div ref="searchFarmerDialog" class="modal fade" data-bs-backdrop="static" id="searchFarmerDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Search Member</h5>
                    <button type="button" class="btn-close" @click="close()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="input-group mb-3">
                            <div class="form-floating fs-middle">
                                <input id="phone" type="text" class="form-control mr-3" placeholder="9889898898"
                                    v-model="phone" @focus="v$.phone.$reset()" maxlength="10"
                                    :disabled="farmerId !== null" />
                                <label for="phone">Phone Number</label>
                            </div>
                            <button type="button" class="btn btn-primary ml-3" @click="searchFarmer()" :disabled="disableOtp()">
                                Send OTP
                            </button>
                            
                            </div>
                            <div v-if="v$.phone.$error" class="error-message mb-2">
                                <p v-for="error of v$.phone.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                            <div v-if="farmerNotFound" class="error-message mb-2">
                                <strong>Member not found</strong>
                            </div>
                            <otp-progress-indicator :start="startTimer" @timedOut="timedOut()" />
                            <div v-if="showOtp">
                                <div class="form-floating fs-middle mb-3">
                                    <input id="otp" type="text" class="form-control" v-model="otp" maxlength="6">
                                    <label for="otp">OTP</label>
                                </div>
                                <button id="loginBtn" class="w-100 btn btn-primary" type="button" @click="submitOTP">Submit</button>
                            </div>
                            </form>
                            
                            
                            
                            <div class="card" v-if="farmer">
                                <div class="card-header">
                                    <strong>Member Details</strong>
                                </div>
                                <div class="card-body">
                                    <dl class="row">
                                        <dt class="col-6">Name</dt>
                                        <dd class="col-6">{{farmer.firstName}} {{farmer.lastName}}</dd>
                            
                                        <dt class="col-6">Address</dt>
                                        <dd class="col-6">{{farmer.address}}</dd>
                            
                                        <dt class="col-6">Taluka</dt>
                                        <dd class="col-6">{{farmer.taluka}}</dd>
                            
                                        <dt class="col-6">District</dt>
                                        <dd class="col-6">{{farmer.district}}</dd>
                            
                                        <dt class="col-6">State</dt>
                                        <dd class="col-6">{{farmer.state}}</dd>
                            
                                        <dt class="col-6">Available Credit</dt>
                                        <dd class="col-6">{{farmer.availableCredit}}</dd>
                                    </dl>
                        </div>
                        <div class="card-footer text-center">
                            <button type="button" class="btn btn-primary" @click="selectFarmer()">
                                Select Member
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="close()">
                        Cancel
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { mapActions } from 'vuex';
import { required, numeric, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import otpProgressIndicator from "@/components/common/OtpProgressIndicator.vue";

export default {
    name: "SearchFarmerDialog",
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    components: {
        otpProgressIndicator
    },
    data() {
        return {
            startTimer: false,
            searchFarmerDialog: null,
            otp: '',
            phone: null,
            farmerId: null,
            farmer: null,
            farmerNotFound: false,
            showOtp: false,
        };
    },
    validations() {
        return {
            phone: { required, numeric, minLength: minLength(10) },
        }
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        },
        retailerId: {
            type: String,
            required: true,
        }
    },
    watch: {
        showHide: function (newVal) {
            if (newVal) {
                this.searchFarmerDialog.show();
            } else {
                this.searchFarmerDialog.hide();
            }
        }
    },
    methods: {
        ...mapActions(['searchFarmerByPhone', 'retailerFarmerSearchStep1', 'retailerFarmerSearchStep2']),
        disableOtp() {
            return this.startTimer || this.farmer !== null
        },
        timedOut() {
            this.startTimer = false;
        },
        close() {
            this.v$.$reset()
            this.phone = null
            this.farmer = null
            this.farmerId = null
            this.otp = ''
            this.farmerNotFound = false
            this.$emit('closed')
        },
        async searchFarmer() {
            const result = await this.v$.$validate()

            if (result) {
                this.farmerNotFound = false
                let response = await this.retailerFarmerSearchStep1({
                    phone: this.phone,
                    retailerId: this.retailerId
                })
                console.log(response)
                if (response.success) {
                    this.farmerId = response.data.farmerUid
                    this.showOtp = true
                    this.startTimer = true
                } else {
                    this.farmerNotFound = true
                }
            }
        },
        async submitOTP() {
            let response = await this.retailerFarmerSearchStep2({
                otp: this.otp,
                farmerId: this.farmerId,
                retailerId: this.retailerId
            })

            if (response.success) {
                if (Object.keys(response.data.farmer).length !== 0) {
                    this.farmer = response.data.farmer
                } else {
                    this.farmerNotFound = true
                }
                this.otp = ''
                this.showOtp = false
            }
        },
        selectFarmer() {
            let payload = { ...this.farmer }
            this.v$.$reset()
            this.farmer = null
            this.phone = null
            this.farmerId = null
            this.otp = ''
            this.farmerNotFound = false
            this.$emit('selected', {
                farmer: payload
            })
        },
        clearFarmer() {
            this.v$.$reset()
            this.phone = null

            this.otp = ''
            this.farmer = null
            this.farmerNotFound = false
        }
    },
    async mounted() {
        this.searchFarmerDialog = new Modal(this.$refs.searchFarmerDialog, {});
        if (this.showHide) {
            this.searchFarmerDialog.show();
        }
    },
}
</script>
<style scoped>

</style>