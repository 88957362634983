<template>
  <base-layout>
    <div id="content-container" class=" d-flex justify-content-center align-items-center">
      <div>
      <div class="fs-logo-container text-center">
        <span class="fs-logo">FarmServ</span
        ><span class="fs-product-logo">Infinity</span>
      </div>
      <div class="fs-logo-container text-center">
        <span class="m-logo">Under</span
        ><span class="m-product-logo">Maintainence</span>
      </div>
    </div>
    </div>
  </base-layout>
</template>

<script>
import baseLayout from "@/layouts/base.vue";

export default {
  name: "MaintainenceView",
  components: {
    baseLayout,
  },
};
</script>

<style scoped>
#content-container {
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
}

.fs-logo {
  font-size: 22px;
  font-weight: 400;
  margin-right: 5px;
}

.fs-product-logo {
  font-size: 22px;
  font-weight: 700;
}

.m-logo {
  font-size: 32px;
  font-weight: 500;
  margin-right: 5px;
  color: #00477e;
}

.m-product-logo {
  font-size: 32px;
  font-weight: 800;
  color: #0064b2;
}

.fs-logo-container {
  margin: 20px 0;
}
</style>