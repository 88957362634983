const getDisplayLoader = (state) => {
    return state.displayLoader
}

const getLoggedUser = (state) => {
    return state.loggedUser
}

const getToken = (state) => {
    console.log(state.token)
    return state.token
}

const getStates = (state) => {
    console.log(state.token)
    return state.states
}

const getSettings = (state) => {
    console.log(state.settings)
    return state.settings
}

export default {
    getDisplayLoader,
    getLoggedUser,
    getToken,
    getStates,
    getSettings
}