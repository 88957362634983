<template>
    <div>
        <top-navbar-component />
        <div class="container-fluid">
            <div class="row">
                <sidebar-component />
                <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <slot />
                </main>
            </div>
        </div>
    </div>
</template>
<script>
import topNavbarComponent from "@/components/TopNavBar";
import sidebarComponent from "@/components/SideBar";

export default ({
    name: "AdminLayout",
    data() {
        return {};
    },
    components: {
        topNavbarComponent,
        sidebarComponent,
    },
})
</script>
<style scoped>

</style>
