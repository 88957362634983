
const state = {
    token: localStorage.getItem('userTpe'), //should be null in prod
    displayLoader: false,
    userType: localStorage.getItem('userType'),
    termsAccepted: localStorage.getItem('termsAccepted'),
    uid: localStorage.getItem('uid'),
    loggedUser: localStorage.getItem('loggedUser'), // Empty user is for testing locally without login, should be null in prod
    farmers: [],
    retailers: [],
    traders: [],
    farmer: null,
    retailer: null,
    trader: null,
    states: {},
    settings: null,
    $bus: null
}

export default state;