<template>
  <admin-layout>

    <div class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      ">
      <h1 class="h2">Users</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">
          <button type="button" class="btn btn-sm btn-outline-secondary" @click="addUser()">
            Add User
          </button>
        </div>
      </div>
    </div>
    <div>
      <div class="table-responsive">
        <table class="table table-striped" v-if="users?.length > 0">
          <thead>
            <tr>
              <th scope="col">Phone</th>
              <th scope="col">Added On</th>
              <th scope="col">Role</th>
              <th scope="col">Login Allowed</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(user) in users" :key="user">
              <tr>
                <td>{{ user.phone }}</td>
                <td>{{ user.createdOn }}</td>
                <td>{{ user.userType }}</td>
                <td>{{ user.allowLogin ? 'Yes' : 'No' }}</td>
                <td>
                  <div class="d-flex">
                    <i class="material-icons">keyboard_double_arrow_right</i>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <add-user-dialog :showHide="showHide" @userCreated="userCreated" @userClosed="userClosed" />
  </admin-layout>
</template>

<script>
import adminLayout from "@/layouts/admin.vue";
import addUserDialog from "@/components/common/AddUserDialog.vue";
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: "UsersList",
  components: {
    adminLayout,
    addUserDialog
  },
  setup() {
    return {
      toast: useToast()
    }
  },  
  data() {
    return {
      users: [],
      showHide: false
    };
  },
  methods: {
    ...mapActions(["getUsers"]),
    addUser() {
      this.showHide = true
    },
    async userCreated(data) {
      this.showHide = false;
      if (data.success) {
        this.toast.success("User addition successful");
        let response = await this.getUsers({})
        if (response.success) {
          this.users = response.data.users
        }
      } else {
        this.toast.success("User addition failed");
      }

    },
    userClosed() {
      this.showHide = false;
    }

  },
  async mounted() {
    let response = await this.getUsers({})
    if (response.success) {
      this.users = response.data.users
    }
  },
};
</script>

<style scoped>
.item-key {
  min-width: 200px;
}

.item-value {
  min-width: 400px;
}
</style>