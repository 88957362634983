<template>
  <admin-layout>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Receivables</h1>
    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Amount Received</th>
            <th scope="col">Receipt Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="farmer in farmers" :key="farmer.uid">
            <td>{{ farmer.index }}</td>
            <td>{{ farmer.firstName }} {{ farmer.lastName }}</td>
            <td>{{ farmer.phone }}</td>
            <td>{{ farmer.amountReceived }}</td>
            <td>{{ farmer.receiptDate }}</td>
          </tr>
          </tbody>
          </table>
          </div>
          <div class="mt-4">
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center">
                <li class="page-item disabled"><a class="page-link" href="#">Previous</a></li>
                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>    
  </admin-layout>
</template>

<script>
import adminLayout from "@/layouts/admin";
// import { mapActions, mapState } from "vuex";

export default {
  name: "ReceivablesView",
  components: {
    adminLayout,
  },
  data() {
    return {
      farmers: [
        {
          index: 1,
          uid: "1",
          firstName: "Ut amet",
          lastName: "exercitation Excepteur",
          phone: 76666028,
          address: "irure minim",
          taluka: "amet",
          district: "consequat est amet nisi",
          state: "eiusmod",
          pincode: 43341199,
          amountReceived: 2000,
          receiptDate: "01/09/2022",
        },
        {
          index: 2,
          uid: "2",
          firstName: "irure",
          lastName: "incididunt labore non",
          phone: 59660866,
          address: "sed voluptate",
          taluka: "in ex",
          district: "aliquip Lorem anim irure mollit",
          state: "quis aute tempor",
          pincode: 10981328,
          amountReceived: 2000,
          receiptDate: "01/09/2022",
        },
        {
          index: 3,
          uid: "3",
          firstName: "irure",
          lastName: "incididunt labore non",
          phone: 59660866,
          address: "sed voluptate",
          taluka: "in ex",
          district: "aliquip Lorem anim irure mollit",
          state: "quis aute tempor",
          pincode: 10981328,
          amountReceived: 2000,
          receiptDate: "01/09/2022",
        },
        {
          index: 4,
          uid: "4",
          firstName: "irure",
          lastName: "incididunt labore non",
          phone: 59660866,
          address: "sed voluptate",
          taluka: "in ex",
          district: "aliquip Lorem anim irure mollit",
          state: "quis aute tempor",
          pincode: 10981328,
          amountReceived: 2000,
          receiptDate: "01/09/2022",
        },
      ],      
    };
  },
  computed: {
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.scrollToTop();
  },
};
</script>

<style scoped>

</style>