import jwt_decode from "jwt-decode";

const removeUser = (state) => {
    localStorage.removeItem('loggedUser')
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('uid');
    localStorage.removeItem('termsAccepted');
    state.userType = null;
    state.loggedUser = null;
    state.token = null;
    state.uid = null;
    state.termsAccepted = null;
}

const refreshUser = (state) => {
    state.token = localStorage.getItem('token')
    state.loggedUser = localStorage.getItem('loggedUser')
    state.userType = localStorage.getItem('userType')
    state.uid = localStorage.getItem('uid')
    state.termsAccepted = localStorage.getItem('termsAccepted')
}

const setUser = (state, data) => {
    localStorage.setItem('loggedUser', JSON.stringify(data.user))
    localStorage.setItem('token', data.user.token)
    const decodedToken = jwt_decode(data.user.token);

    localStorage.setItem('userType', decodedToken.userType)
    localStorage.setItem('uid', decodedToken.uid)
    localStorage.setItem('termsAccepted', decodedToken.termsAccepted)

    state.token = data.user.token
    state.loggedUser = decodedToken
    state.userType = decodedToken.userType
    state.uid = decodedToken.uid
    state.termsAccepted = decodedToken.termsAccepted
    console.log(decodedToken)
}

const setFarmers = (state, data) => {
    state.farmers = data.data.farmers
    console.log(data.data.farmers)
}

const setRetailers = (state, data) => {
    state.retailers = data.retailers
    console.log(state.retailers)
}

const setTraders = (state, data) => {
    state.traders = data.traders
    console.log(state.traders)
}

const setTrader = (state, data) => {
    state.trader = data.trader
    console.log(data.trader)
}

const setSettings = (state, data) => {
    state.settings = data.data
    console.log(data.data)
}

const setRetailer = (state, data) => {
    state.retailer = data.retailer
    console.log(data.retailer)
}

const setFarmer = (state, data) => {
    state.farmer = data.farmer
    console.log(state.farmer)
}

const setStates = (state, data) => {
    state.states = data.states
}

const setBus = (state, data) => {
    state.$bus = data
}

export default {
    removeUser,
    setUser,
    refreshUser,
    setFarmers,
    setRetailers,
    setTraders,
    setFarmer,
    setTrader,
    setRetailer,
    setStates,
    setSettings,
    setBus
}