<template>
  <base-layout>
    <div id="content-container">
      <div id="reg" class="form-reg w-100 m-auto text-center">
        <form>
          <h3>FarmServ Infinity Member Registration</h3>

          <h5 class="h5 mb-3 fw-normal">Please enter registration information</h5>

          <div class="form-floating fs-top">
            <input id="fname" type="text" class="form-control" placeholder="FirstName" v-model="newTrader.firstName"
              @focus="v$.newTrader.firstName.$reset()" />
            <label for="fname">First Name</label>
            <div v-if="v$.newTrader.firstName.$error" class="error-message mb-2">
              <p v-for="error of v$.newTrader.firstName.$errors" :key="error.$uid">
                <strong>{{ error.$message }}</strong>
              </p>
            </div>
          </div>

          <div class="form-floating fs-middle">
            <input id="lname" type="text" class="form-control" placeholder="LastName" v-model="newTrader.lastName"
              @focus="v$.newTrader.lastName.$reset()" />
            <label for="lname">Last Name</label>
            <div v-if="v$.newTrader.lastName.$error" class="error-message mb-2">
              <p v-for="error of v$.newTrader.lastName.$errors" :key="error.$uid">
                <strong>{{ error.$message }}</strong>
              </p>
            </div>
          </div>

          <div class="form-floating fs-middle">
            <input id="phone" type="text" class="form-control" placeholder="9889898898" v-model="newTrader.phone"
              @focus="v$.newTrader.phone.$reset()" maxlength="10" />
            <label for="phone">Phone Number</label>
            <div v-if="v$.newTrader.phone.$error" class="error-message mb-2">
              <p v-for="error of v$.newTrader.phone.$errors" :key="error.$uid">
                <strong>{{ error.$message }}</strong>
              </p>
            </div>
          </div>

          <div class="form-floating fs-middle">
            <input id="address" type="text" class="form-control" placeholder="Address" v-model="newTrader.address"
              @focus="v$.newTrader.address.$reset()" />
            <label for="address">Address</label>
            <div v-if="v$.newTrader.address.$error" class="error-message mb-2">
              <p v-for="error of v$.newTrader.address.$errors" :key="error.$uid">
                <strong>{{ error.$message }}</strong>
              </p>
            </div>
          </div>

          <div class="form-floating fs-middle">
            <select class="form-select" id="state" aria-label="State" v-model="newTrader.state" :disabled="states === {}"
              @change="stateSelected">
              <option selected value="NA">Select State</option>
              <option v-for="state in Object.keys(states)" :value="state" :key="state">{{state}}</option>
            </select>
            <label for="state">State</label>
            <div v-if="v$.newTrader.state.$error" class="error-message mb-2">
              <p v-for="error of v$.newTrader.state.$errors" :key="error.$uid">
                <strong>{{ error.$message }}</strong>
              </p>
            </div>
            </div>
            
            <div class="form-floating fs-middle">
              <select class="form-select" id="district" aria-label="district" v-model="newTrader.district"
                :disabled="newTrader.state === 'NA'" @change="districtSelected">
                <option selected value="NA">Select District</option>
                <option v-for="district in districts" :value="district" :key="district">
                  {{district}}
                </option>
              </select>
              <label for="district">District</label>
              <div v-if="v$.newTrader.district.$error" class="error-message mb-2">
                <p v-for="error of v$.newTrader.district.$errors" :key="error.$uid">
                  <strong>{{ error.$message }}</strong>
                </p>
              </div>
            </div>
            
            <div class="form-floating fs-middle">
              <select class="form-select" id="taluka" aria-label="taluka" v-model="newTrader.taluka"
                :disabled="newTrader.district === 'NA'">
                <option selected value="NA">Select Taluka</option>
                <option v-for="taluka in talukas" :value="taluka" :key="taluka">
                  {{taluka}}
                </option>
              </select>
              <label for="taluka">Taluka</label>
              <div v-if="v$.newTrader.taluka.$error" class="error-message mb-2">
                <p v-for="error of v$.newTrader.taluka.$errors" :key="error.$uid">
                  <strong>{{ error.$message }}</strong>
                </p>
              </div>
            </div>
            
            <div class="form-floating fs-bottom">
              <input id="pincode" type="text" class="form-control" placeholder="Pincode" v-model="newTrader.pincode"
                @focus="v$.newTrader.pincode.$reset()" maxlength="6" />
              <label for="pincode">Pincode</label>
              <div v-if="v$.newTrader.pincode.$error" class="error-message mb-2">
                <p v-for="error of v$.newTrader.pincode.$errors" :key="error.$uid">
                  <strong>{{ error.$message }}</strong>
                </p>
              </div>
            </div>
            
            <div class="row mb-3">
              <div class="col-9">
                <div class="form-floating">
                  <input id="traderPhone" type="text" class="form-control" placeholder="123456" v-model="newTrader.traderPhone">
                  <label for="traderPhone">Trader Phone</label>
                </div>
              </div>
              <div class="col-3">
                <button id="loginBtn" class="w-100 btn btn-primary" type="button" @click="searchTrader()">Search</button>
              </div>
            </div>
            
            <div class="row mb-3" v-if="traderResult">
              <div class="col-12">
                {{traderResult.firstName}} {{traderResult.lastName}}
              </div>
            </div>
            
            <button id="otpBtn" class="w-100 btn btn-primary" type="button" @click="saveRetailer()">Get OTP</button>
            
            <div v-if="otpEnabled">
              <div class="form-floating">
                <input id="otp" type="text" class="form-control" placeholder="123456">
                <label for="otp">OTP</label>
              </div>
            
              <button id="loginBtn" class="w-100 btn btn-primary" type="button">Verify OTP</button>
            </div>
          <p class="mt-5 mb-3 text-muted">Copyright &copy;2022 FarmServ </p>
        </form>
      </div>
    </div>
  </base-layout>
</template>

<script>
import baseLayout from "@/layouts/base.vue";
import { mapActions, mapState } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, numeric, minLength } from '@vuelidate/validators'

const locationSelection = (value) => value !== "NA"

export default {
  name: "FarmerRegistrationView",
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  components: {
    baseLayout,
  },
  data() {
    return {
      newTrader: {
        state: "NA",
        district: "NA",
        taluka: "NA",
        firstName: null,
        lastName: null,
        phone: null,
        address: null,
        pincode: null,
        traderPhone: null,
      },
      districts: [],
      talukas: [],
      otpEnabled: false,
      otp: '',
      traderResult: null
    };
  },
  validations() {
    return {
      newTrader: {
        firstName: { required },
        lastName: { required },
        address: { required },
        phone: { required, numeric, minLength: minLength(10) },
        traderPhone: { required, numeric, minLength: minLength(10) },
        pincode: { required, numeric, minLength: minLength(6) },
        district: { locationSelection: helpers.withMessage('Value is required', locationSelection) },
        state: { locationSelection: helpers.withMessage('Value is required', locationSelection) },
        taluka: { locationSelection: helpers.withMessage('Value is required', locationSelection) }
      },
      activeStateIndex: -1
    }
  },
  computed: {
    ...mapState(["states"]),
  },
  methods: {
    ...mapActions(["getStates", "farmerRegStep1", "searchTraderByPhone"]),
    async saveRetailer() {
      const result = await this.v$.$validate()
      if (result) {
        console.log("Save Trader")
        this.newTrader.traderId = this.traderResult.uid
        let createresult = await this.farmerRegStep1(this.newTrader);
        console.log(createresult)

        if (createresult) {
          this.newTrader = {
            state: "NA",
            district: "NA",
            taluka: "NA",
            firstName: null,
            lastName: null,
            phone: null,
            address: null,
            pincode: null,
            newTrader: null
          };
          this.otpEnabled = true
          this.v$.$reset()
        }
      }
    },
    stateSelected() {
      if (this.newTrader.state === "NA") {

        this.newTrader.district = "NA"
        this.newTrader.taluka = "NA"
        this.districts = []
        this.talukas = []
        return;
      } else {
        this.districts = Object.keys(this.states[this.newTrader.state].districts)
      }
    },
    districtSelected() {
      if (this.newTrader.district === "NA") {
        this.talukas = []
        this.newTrader.taluka = "NA"
        return;
      } else {
        this.talukas = this.states[this.newTrader.state].districts[this.newTrader.district].talukas.map((value) => {
          return value.name;
        })
      }
    },
    async searchTrader() {
      let result = await this.searchTraderByPhone({
        phone: this.newTrader.traderPhone
      });

      if (result !== false) {
        console.log(result)
        this.traderResult = result.trader
      }
    }
  },
  async mounted() {
    if (Object.keys(this.states).length === 0) {
      await this.getStates({});
    }
  },
};
</script>

<style scoped>
#content-container {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  height: 100vh;
}

.form-reg {
  max-width: 600px;
  padding: 15px;
}

.form-reg .form-floating:focus-within {
  z-index: 2;
}

.form-reg .fs-top input[type],
select {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-reg .fs-middle input[type],
select {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-reg .fs-bottom input[type] {
  margin-bottom: 10px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.form-reg #otp {
  margin-bottom: 10px;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.form-reg #otpBtn {
  margin-bottom: 10px;
}
</style>