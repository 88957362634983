<template>
    <div ref="addItemDialog" class="modal fade" data-bs-backdrop="static" id="addItemDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Product</h5>
                    <button type="button" class="btn-close" @click="close()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating fs-top mb-3">
                            <input id="name" type="text" class="form-control" placeholder="Product name"
                                v-model="item.name" @focus="v$.item.name.$reset()" />
                            <label for="name">Product name</label>
                            <div v-if="v$.item.name.$error" class="error-message mb-2">
                                <p v-for="error of v$.item.name.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class="input-group mb-3">
                            <div class="form-floating fs-top mb-3">
                                <input id="packSize" type="text" class="form-control" placeholder="Pack Size" v-model="item.packSize"
                                    @focus="v$.item.packSize.$reset()" />
                                <label for="packSize">Pack Size</label>
                                <div v-if="v$.item.packSize.$error" class="error-message mb-2">
                                    <p v-for="error of v$.item.packSize.$errors" :key="error.$uid">
                                        <strong>{{ error.$message }}</strong>
                                    </p>
                                </div>
                            </div>
                        
                            <div class="form-floating fs-middle mb-3">
                                <select class="form-select" id="packSizeUnit" aria-label="Pack Size" v-model="item.packSizeUnit">
                                    <option selected value="NA">Select Unit</option>
                                    <option v-for="packSizeUnit in packSizeUnits" :value="packSizeUnit" :key="packSizeUnit">{{packSizeUnit}}
                                    </option>
                                </select>
                                <label for="packSizeUnit">Unit</label>
                                <div v-if="v$.item.packSizeUnit.$error" class="error-message mb-2">
                                    <p v-for="error of v$.item.packSizeUnit.$errors" :key="error.$uid">
                                        <strong>{{ error.$message }}</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="form-floating fs-middle mb-3">
                            <input id="quantity" type="text" class="form-control" placeholder="Quantity (packets/bottles)"
                                v-model="item.quantity" @focus="v$.item.quantity.$reset()" />
                            <label for="quantity">Quantity (pcs)</label>
                            <div v-if="v$.item.quantity.$error" class="error-message mb-2">
                                <p v-for="error of v$.item.quantity.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>
                        
                        <div class="form-floating fs-middle mb-3">
                            <input id="ratePerPack" type="text" class="form-control" placeholder="Rate per packet/bottle (GST included)"
                                v-model="item.ratePerPack" @focus="v$.item.ratePerPack.$reset()" />
                            <label for="ratePerPack">Rate per pcs (GST included)</label>
                            <div v-if="v$.item.ratePerPack.$error" class="error-message mb-2">
                                <p v-for="error of v$.item.ratePerPack.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>
                        
                        <div class="alert alert-success" v-if="totalValue">
                            <p class="mb-1"><strong>Total Value</strong></p>
                            <strong>Rs. {{totalValue}}</strong>
                        </div>

                        <div class="form-floating fs-middle mb-3">
                            <select class="form-select" id="state" aria-label="State" v-model="item.gst">
                                <option selected value="NA">Select applicable GST rate</option>
                                <option v-for="gstSlab in gstSlabs" :value="gstSlab" :key="gstSlab">{{gstSlab}}
                                </option>
                            </select>
                            <label for="state">GST</label>
                            <div v-if="v$.item.gst.$error" class="error-message mb-2">
                                <p v-for="error of v$.item.gst.$errors" :key="error.$uid">
                                    <strong>{{ error.$message }}</strong>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="close()">
                        Cancel
                    </button>
                    <div class="card-footer text-center">
                        <button type="button" class="btn btn-primary" @click="addItem()">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { required, numeric, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
    name: "AddItemDialog",
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            addItemDialog: null,
            item: {
                name: null,
                packSize: null,
                packSizeUnit: null,
                quantity: null,
                ratePerPack: null,
                gst: null,
            },
            packSizeUnits: ['Kg', 'Gm', 'Lt', 'Ml'],
            gstSlabs: ['0%', '5%', '12%', '18%', '28%']
        };
    },
    validations() {
        return {
            item: {
                name: { required, minLength: minLength(2) },
                packSize: { required },
                packSizeUnit: { required },
                quantity: { required, numeric },
                ratePerPack: { required, numeric },
                gst: { required },
            }
        }
    },
    computed: {
        totalValue() {
            if (this.item.quantity != null && this.item.ratePerPack != null
                && !isNaN(+this.item.quantity) && !isNaN(+this.item.ratePerPack)) {
                return this.item.quantity * this.item.ratePerPack
            }
            return ""
        }
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        showHide: function (newVal) {
            if (newVal) {
                this.addItemDialog.show();
            } else {
                this.addItemDialog.hide();
            }
        }
    },
    methods: {
        close() {
            this.v$.$reset()
            this.item = {
                name: null,
                packSize: null,
                packSizeUnit: null,
                quantity: null,
                ratePerPack: null,
                gst: null,
            }
            this.$emit('closed')
        },
        async addItem() {
            const result = await this.v$.$validate()

            if (result) {
                let payload = { ...this.item }
                payload.totalValue = this.totalValue
                this.v$.$reset()
                this.item = {
                    name: null,
                    packSize: null,
                    packSizeUnit: null,
                    quantity: null,
                    ratePerPack: null,
                    gst: null,
                }
                this.$emit('itemAdded', {
                    item: payload
                })
            }
        },
    },
    async mounted() {
        this.addItemDialog = new Modal(this.$refs.addItemDialog, {});
        if (this.showHide) {
            this.addItemDialog.show();
        }
    },
}
</script>
<style scoped>

</style>