<template>
  <retailer-layout>
    <template #heading>Retailer Invoice</template>
    <template #content>
      <div v-if="!invoiceAvailable">
        Invoice not generated
      </div>
      <div class="table-responsive" v-else>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Order Time</th>
              <th scope="col">Amount</th>
              <th scope="col">Member Name</th>
              <th scope="col">Phone</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) in orders" :key="order.id">
              <td>{{ order.orderTime }}</td>
              <td>{{ order.amount }}</td>
              <td>{{ order.farmer.firstName }} {{ order.farmer.lastName }}</td>
              <td>{{ order.farmer.phone }}</td>
              <td>
                <a
                  class="delete"
                  title="Block"
                  data-toggle="tooltip"
                  @click.prevent="showItems(index)"
                  ><i class="material-icons">&#xE254;</i></a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <items-dialog
          :showHide="showHideItems"
          :items="items"
          @closed="itemsClosed()"
        />
      </div>
    </template>
  </retailer-layout>
</template>

<script>
import retailerLayout from "@/layouts/retailer.vue";
import { mapActions } from "vuex";
import itemsDialog from "@/components/common/ItemsDialog.vue";

export default {
  name: "RetailerInvoice",
  components: {
    retailerLayout,
    itemsDialog,
  },
  data() {
    return {
      retailerId: this.$route.params.rid,
      invoiceAvailable: false,
      orders: [],
      items: null,
      showHideItems: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions(["getRetailerSales"]),
    showItems(index) {
      console.log(index);
      this.items = this.orders[index].items;
      this.showHideItems = true;
    },
    itemsClosed() {
      this.items = [];
      this.showHideItems = false;
    },
  },
  async mounted() {
    let response = await this.getRetailerSales({
      retailerId: this.retailerId,
    });
    if (response.success) {
      this.orders = response.data.orders;
    }
  },
};
</script>

<style scoped>
.item-key {
  min-width: 200px;
}

.item-value {
  min-width: 400px;
}
</style>