<template>
  <retailer-layout>
    <template #heading>Create Order</template>
    <template #content>
      <div class="card">
        <div class="card-header">
          <strong>Member Details</strong>
        </div>
        <div class="card-body" v-if="farmer">
          <dl class="row">
            <dt class="col-6">Name</dt>
            <dd class="col-6">{{ farmer.firstName }} {{ farmer.lastName }}</dd>
            <dt class="col-6">Address</dt>
            <dd class="col-6">{{ farmer.address }}</dd>

            <dt class="col-6">Taluka</dt>
            <dd class="col-6">{{ farmer.taluka }}</dd>

            <dt class="col-6">District</dt>
            <dd class="col-6">{{ farmer.district }}</dd>

            <dt class="col-6">State</dt>
            <dd class="col-6">{{ farmer.state }}</dd>

            <dt class="col-6">Available Credit</dt>
            <dd class="col-6">{{ farmer.availableCredit }}</dd>
          </dl>
        </div>
        <div class="card-footer text-center">
          <button
            v-if="farmer"
            type="button"
            class="btn btn-primary"
            @click="clearFarmer()"
            :disabled="disableAfterSubmit"
          >
            Clear Member
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            @click="searchFarmer()"
          >
            Select Member
          </button>
        </div>
      </div>
      <div class="text-center mt-4" v-if="farmer != null">
        <button
          type="button"
          class="btn btn-secondary"
          @click="addItem()"
          :disabled="disableAfterSubmit"
        >
          Add Product
        </button>
      </div>
      <div v-if="items && items.length > 0">
        <div class="card mt-2" v-for="(item, index) in items" :key="item">
          <div class="card-header bg-dark">
            <button
              type="button"
              class="btn-close btn-close-white"
              aria-label="Delete"
              @click="deleteItem(index)"
              :disabled="disableAfterSubmit"
            ></button>
          </div>
          <div class="card-body">
            <dl class="row">
              <dt class="col-6">Product name</dt>
              <dd class="col-6">{{ item.name }}</dd>
              <dt class="col-6">Pack size</dt>
              <dd class="col-6">{{ item.packSize }}</dd>
              <dt class="col-6">Quantity (packets/bottles)</dt>
              <dd class="col-6">{{ item.quantity }}</dd>
              <dt class="col-6">Rate per packet/bottle (GST included)</dt>
              <dd class="col-6">{{ item.ratePerPack }}</dd>
              <dt class="col-6">Total value ( GST included)</dt>
              <dd class="col-6">{{ item.totalValue }}</dd>
              <dt class="col-6">GST rate applicable</dt>
              <dd class="col-6">{{ item.gst }}</dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="alert alert-success mt-4" v-if="totalAmount">
        <dl class="row">
          <dt class="col-6">Total Amount</dt>
          <dd class="col-6">
            <strong>Rs. {{ totalAmount }}</strong>
          </dd>
          <div class="row" v-if="totals.adjustmentApplied">
            <dt class="col-6">Adjustments</dt>
            <dd class="col-6">
              <strong
                >{{ totals.adjustmentType }}
                {{ totals.adjustmentAmount }}</strong
              >
            </dd>
          </div>
          <dt class="col-6">Final Amount</dt>
          <dd class="col-6">
            <strong>Rs. {{ totals.finalAmount }}</strong>
          </dd>
        </dl>

        <div>
          <button
            v-if="!totals.adjustmentApplied"
            type="button btn btn-primary btn-sm"
            class="btn btn-primary"
            @click="adjustTotalAmount()"
            :disabled="disableAfterSubmit"
          >
            Adjust Amount
          </button>
          <button
            v-else
            type="button"
            class="btn btn-danger btn-sm"
            @click="clearAdjustments()"
            :disabled="disableAfterSubmit"
          >
            Clear Adjustment
          </button>
        </div>
      </div>
      <div v-if="adjustments.adjustTotalAmount" class="text-center mt-4">
        <form class="row align-items-center">
          <div class="col-auto col-sm-12">
            <div class="form-floating fs-top mb-3">
              <select
                class="form-select"
                id="adjustmentType"
                aria-label="State"
                v-model="adjustments.adjustmentType"
              >
                <option selected value="NA">Select Adjustment Type</option>
                <option
                  v-for="adjustmentType in adjustments.adjustmentTypes"
                  :value="adjustmentType"
                  :key="adjustmentType"
                >
                  {{ adjustmentType }}
                </option>
              </select>
              <label for="adjustmentType">Adjustment Type</label>
            </div>
          </div>
          <div class="col-auto col-sm-12">
            <div class="form-floating fs-top mb-3">
              <input
                type="text"
                class="form-control"
                id="adjustmentAmount"
                placeholder="Adjustment Amount"
                v-model="adjustments.amount"
              />
              <label for="adjustmentAmount">Adjustment Amount</label>
            </div>
          </div>
          <div class="col-auto col-sm-12">
            <button
              type="button"
              class="btn btn-secondary"
              @click="applyAdjustment()"
              :disabled="disableAfterSubmit"
            >
              Apply
            </button>
          </div>
        </form>
      </div>

      <div v-if="showAmount" class="mt-4 text-center">
        <button
          type="button"
          class="btn btn-secondary mb-4"
          @click="submitPurchase()"
          :disabled="disableAfterSubmit"
        >
          Submit Order
        </button>
      </div>
      <div v-if="showOtp" class="text-center mt-4 mb-4">
        <div class="form-floating fs-bottom mb-3">
          <input
            id="otp"
            type="text"
            class="form-control"
            placeholder="OTP"
            v-model="otp"
            minlength="6"
            maxlength="6"
          />
          <label for="otp">OTP</label>
        </div>

        <div class="d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-primary me-2"
            @click="verifyOtp()"
          >
            Submit OTP
          </button>
          <button
            type="button"
            class="btn btn-secondary ms-2"
            @click="clearOrder()"
          >
            Clear Order
          </button>
        </div>
      </div>

      <search-farmer-dialog
        :showHide="showSearch"
        :retailerId="retailerId"
        @selected="farmerSelected"
        @closed="searchClosed"
      />
      <add-item-dialog
        :showHide="showAddItem"
        @itemAdded="itemAdded"
        @closed="addItemClosed"
      />
    </template>
  </retailer-layout>
</template>

<script>
import retailerLayout from "@/layouts/retailer.vue";
import searchFarmerDialog from "@/components/common/SearchFarmerDialog.vue";
import addItemDialog from "@/components/common/AddItemDialog.vue";
import { required, numeric } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: "RetailerAddFarmerPurchaseView",
  components: {
    retailerLayout,
    searchFarmerDialog,
    addItemDialog,
  },
  setup() {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    };
  },
  data() {
    return {
      disableAfterSubmit: false,
      disableSubmit: false,
      retailerId: this.$route.params.rid,
      showSearch: false,
      showAddItem: false,
      showOtp: false,
      farmer: null,
      items: [],
      otp: null,
      orderId: null,
      adjustments: {
        adjustmentTypes: ["Add", "Deduct"],
        adjustmentType: null,
        amount: 0,
        adjustTotalAmount: false,
      },
      totals: {
        adjustmentApplied: false,
        adjustmentType: null,
        adjustmentAmount: 0,
        finalAmount: null,
      },
      recomputeTotalAmount: 0,
    };
  },
  validations() {
    return {
      totalAmount: { required, numeric },
    };
  },
  computed: {
    showAmount() {
      return this.farmer !== null && this.items.length > 0;
    },
    totalAmount() {
      let sum = 0;
      sum = sum + this.recomputeTotalAmount - this.recomputeTotalAmount;
      this.items.forEach((item) => {
        sum += item.totalValue;
      });

      return sum;
    },
  },
  watch: {
    totalAmount: function (val) {
      if (this.totals.adjustmentApplied) {
        if (this.totals.adjustmentType === "Add") {
          this.totals.finalAmount = +val + +this.totals.adjustmentAmount;
        } else if (this.totals.adjustmentType === "Deduct") {
          this.totals.finalAmount = +val - +this.totals.adjustmentAmount;
        }
      } else {
        this.totals.finalAmount = +val;
      }
    },
    "totals.adjustmentApplied": function (val) {
      if (val) {
        if (this.totals.adjustmentType === "Add") {
          this.totals.finalAmount =
            +this.totalAmount + +this.totals.adjustmentAmount;
        } else if (this.totals.adjustmentType === "Deduct") {
          this.totals.finalAmount =
            +this.totalAmount - +this.totals.adjustmentAmount;
        }
      } else {
        this.totals.finalAmount = this.totalAmount;
      }
    },
  },
  methods: {
    ...mapActions(["orderStep1", "orderStep2", "deleteOrder"]),
    searchFarmer() {
      this.showSearch = true;
    },
    clearFarmer() {
      this.farmer = null;
      this.items = [];
    },
    farmerSelected(data) {
      this.showSearch = false;
      if (data.farmer) {
        this.farmer = data.farmer;
      }
    },
    searchClosed() {
      this.showSearch = false;
    },
    addItem() {
      this.showAddItem = true;
    },
    itemAdded(data) {
      this.showAddItem = false;
      this.items.push(data.item);
    },
    addItemClosed() {
      this.showAddItem = false;
    },
    deleteItem(index) {
      this.items.splice(index, 1);
    },
    adjustTotalAmount() {
      this.adjustments.adjustTotalAmount = true;
    },
    applyAdjustment() {
      this.totals.adjustmentApplied = true;
      this.totals.adjustmentType = this.adjustments.adjustmentType;
      this.totals.adjustmentAmount = this.adjustments.amount;

      if (this.adjustments.adjustmentType === "Add") {
        this.totals.finalAmount =
          +this.totalAmount + +this.totals.adjustmentAmount;
      } else if (this.adjustments.adjustmentType === "Deduct") {
        this.totals.finalAmount =
          +this.totalAmount - +this.totals.adjustmentAmount;
      }
    },
    clearAdjustments() {
      this.adjustments.adjustTotalAmount = false;
      this.adjustments.adjustmentType = null;
      this.adjustments.amount = 0;

      this.totals.adjustmentApplied = false;
      this.totals.adjustmentType = null;
      this.totals.adjustmentAmount = null;

      this.recomputeTotalAmount++;
    },
    async submitPurchase() {
      if (this.farmer && this.items.length > 0) {
        console.log("step1");
        // const result = await this.v$.$validate()
        const result = true;

        console.log(result);
        if (result) {
          this.disableAfterSubmit = true;
          this.disableSubmit = true;
          console.log("step2");
          let payload = {
            farmerId: this.farmer.uid,
            retailerId: this.retailerId,
            totalAmount: this.totals.finalAmount,
            items: this.items,
          };
          let result = await this.orderStep1(payload);
          console.log("step3");
          if (result.success) {
            console.log("step4");
            this.orderId = result.data.order.uid;
            this.showOtp = true;
          } else {
            this.disableAfterSubmit = false;
          }
        }
      }
    },
    async verifyOtp() {
      if (this.otp !== null) {
        let result = await this.orderStep2({
          uid: this.orderId,
          otp: this.otp,
        });

        if (result.success) {
          this.resetData();
          this.toast.success("Order Submitted Successfully");
          this.$router.push({ name: "retailerDashboard" });
        }
      }
    },
    async clearOrder() {
      let result = await this.deleteOrder({
        uid: this.orderId,
      });
      if (result.success) {
        this.resetData();
        this.toast.success("Order cleared Successfully");
      }
      this.resetData();
    },
    resetData() {
      this.farmer = null;
      this.items = [];
      this.otp = null;
      this.orderId = null;
      this.disableAfterSubmit = false;
    },
  },
  async mounted() {},
};
</script>

<style scoped>
.item-key {
  min-width: 200px;
}

.item-value {
  min-width: 400px;
}
</style>