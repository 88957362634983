/* eslint-disable no-unused-vars */
import service from '@/utils/http-service'

const logout = async (context) => {
    context.commit('removeUser');
}

const refreshUser = async (context) => {
    context.commit('refreshUser');
}

const getFarmers = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/farmer`);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        context.commit('setFarmers', response.data);
        apiResponse.success = true;
        apiResponse.data = response.data
    }
    return apiResponse;
}

const getTraderFarmers = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/trader/${payload.traderId}/farmer`);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        context.commit('setFarmers', response.data);
        apiResponse.success = true;
        apiResponse.data = response.data
    }
    return apiResponse;
}

const getRetailers = async (context, payload) => {
    console.log(payload)
    const response = await service.get('/retailer', payload);
    console.log(response);
    if (response.status === 200) {
        context.commit('setRetailers', response.data);
        return true;
    } else {
        return false;
    }
}

const getStates = async (context, payload) => {
    console.log(payload)
    const response = await service.get('/common/states', payload);
    console.log(response);
    if (response.status === 200) {
        context.commit('setStates', response.data.data);
        return {
            success: true
        };
    } else {
        return {
            success: false
        };
    }
}

const getRetailersList = async (context, payload) => {
    const response = await service.get('/common/retailers', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true;
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const getTraders = async (context, payload) => {
    console.log(payload)
    const response = await service.get('/trader', payload);
    console.log(response);
    if (response.status === 200) {
        context.commit('setTraders', response.data);
        return true;
    } else {
        return false;
    }
}

const getTrader = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/trader/${payload.traderId}`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        context.commit('setTrader', response.data.data.trader);
        apiResponse.success = true;
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const getFarmer = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/farmer/${payload.farmerId}`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true;
        apiResponse.data = response.data.data
    }
    return apiResponse
}

const getFarmerPurchases = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/farmer/${payload.farmerId}/purchases`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true;
        apiResponse.data = response.data.data
    }
    return apiResponse
}

const getRetailerSales = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/retailer/${payload.retailerId}/sales`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true;
        apiResponse.data = response.data.data
    }
    return apiResponse
}

const getFarmerSales = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/farmer/${payload.farmerId}/sales`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true;
        apiResponse.data = response.data.data
    }
    return apiResponse
}

const getRetailerOrderAmount = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/retailer/${payload.retailerId}/orderAmount`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true;
        apiResponse.data = response.data.data
    }
    return apiResponse
}

const getRetailer = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/retailer/${payload.retailerId}`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        context.commit('setRetailer', response.data.data);
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    console.log(apiResponse.data)
    return apiResponse;
}

const getRetailerPaymentsReceipts = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/retailer/${payload.retailerId}/reports/paymentsReceipts`, {
        params: {
            month: payload.month,
            year: payload.year
        }
    });
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    console.log(apiResponse.data)
    return apiResponse;
}

const loginStep1 = async (context, payload) => {
    const response = await service.post('/auth/login/step1', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse
}


const loginStep2 = async (context, payload) => {
    const response = await service.post('/auth/login/step2', payload);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        context.commit('setUser', response.data.data);
        apiResponse.success = true
    }
    return apiResponse
}

const loginStep3 = async (context, payload) => {
    const response = await service.post('/auth/login/step3', payload);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        context.commit('setUser', response.data.data);
        apiResponse.success = true
    }
    return apiResponse
}

const createTrader = async (context, payload) => {
    const response = await service.post('/trader', payload);
    console.log(response);
    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
}

const createPayment = async (context, payload) => {
    const response = await service.post('/payment', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse
}


const getPaymentsByDateRetailer = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/payment/${payload.retailerId}/byDate`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    console.log(apiResponse.data)
    return apiResponse;
}


const createReceipt = async (context, payload) => {
    const response = await service.post('/receipt', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse
}

const getReceiptByDateFarmer = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/receipt/${payload.farmerId}/byDate`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    console.log(apiResponse.data)
    return apiResponse;
}

const farmerRegStep1 = async (context, payload) => {
    const response = await service.post('/farmer/registration/step1', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const farmerRegStep2 = async (context, payload) => {
    const response = await service.post('/farmer/registration/step2', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const farmerRegStepApprove = async (context, payload) => {
    const response = await service.post('/farmer/registration/approve', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}


const retailerRegStep1 = async (context, payload) => {
    const response = await service.post('/retailer/registration/step1', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const retailerRegStep2 = async (context, payload) => {
    const response = await service.post('/retailer/registration/step2', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const orderStep1 = async (context, payload) => {
    const response = await service.post('/order/submit/step1', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const orderStep2 = async (context, payload) => {
    const response = await service.post('/order/submit/step2', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const deleteOrder = async (context, payload) => {
    const response = await service.delete('/order', { data: payload });
    console.log(response);
    let apiResponse = {
        success: false,
    }
    if (response.status === 200) {
        apiResponse.success = true
    }
    return apiResponse;
}


const retailerFarmerSearchStep1 = async (context, payload) => {
    const response = await service.get(`/retailer/${payload.retailerId}/fsearch`, {
        params: {
            phone: payload.phone
        }
    });
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const retailerFarmerSearchStep2 = async (context, payload) => {
    const response = await service.put(`/retailer/${payload.retailerId}/fsearch`, payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}


const traderRegStep1 = async (context, payload) => {
    const response = await service.post('/trader/registration/step1', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const traderRegStep2 = async (context, payload) => {
    const response = await service.post('/trader/registration/step2', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const userRegStep1 = async (context, payload) => {
    const response = await service.post('/auth/user/step1', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const userRegStep2 = async (context, payload) => {
    const response = await service.post('/auth/user/step2', payload);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const updateTrader = async (context, payload) => {
    const response = await service.put(`/trader/${payload.traderId}`, payload.data);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true;
    }
    return apiResponse;
}

const updateFarmer = async (context, payload) => {
    const response = await service.put(`/farmer/${payload.farmerId}`, payload.data);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true;
    }
    return apiResponse;
}

const updateRetailer = async (context, payload) => {
    const response = await service.put(`/retailer/${payload.retailerId}`, payload.data);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true;
    }
    return apiResponse;
}

const updateCreditLimit = async (context, payload) => {
    const response = await service.put(`/farmer/${payload.farmerId}/creditLimit`, payload.data);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true;
    }
    return apiResponse;
}

const activateFarmer = async (context, payload) => {
    const response = await service.put(`/farmer/${payload.farmerId}/activate`, payload.data);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true
    }
    return apiResponse
}

const activateTrader = async (context, payload) => {
    const response = await service.put(`/trader/${payload.traderId}/activate`, payload.data);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true
    }
    return apiResponse
}

const activateRetailer = async (context, payload) => {
    const response = await service.put(`/retailer/${payload.retailerId}/activate`, payload.data);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true
    }
    return apiResponse
}

const searchTraderByPhone = async (context, payload) => {
    console.log(payload)
    const response = await service.get('/trader/search', {
        params: {
            phone: payload.phone
        }
    });
    console.log(response);
    if (response.status === 200) {
        return response.data;
    } else {

        return false;
    }
}

const searchFarmerByPhone = async (context, payload) => {
    console.log(payload)
    let apiResponse = {
        success: false,
        data: {}
    }
    const response = await service.get('/farmer/search', {
        params: {
            phone: payload.phone
        }
    });
    console.log(response);
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data
    }
    return apiResponse
}

const getSettings = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/common/settings`);
    let apiResponse = {
        success: false
    }
    console.log(response);
    if (response.status === 200) {
        context.commit('setSettings', response.data);
        apiResponse.success = true
    }
    return apiResponse;
}

const updateSettings = async (context, payload) => {
    console.log(payload);
    const response = await service.put(`/common/settings`, payload.data);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true
    }
    return apiResponse;
}

const getTransactionMasterByDate = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/order/transactionMaster/getByDate`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true;
        apiResponse.data = response.data.data
    }
    return apiResponse;
}

const downloadTransactionMasterByDate = async (context, payload) => {
    console.log(payload)
    const response = await service.get(`/order/transactionMaster/getByDate/download`);
    console.log(response);
    let apiResponse = {
        success: false,
        data: {}
    }
    if (response.status === 200) {
        apiResponse.success = true;
        apiResponse.data = response.data
        console.log(response.data)
    }
    return apiResponse;
}

const sendRetailerPaymentSMS = async (context, payload) => {
    console.log(payload);
    const response = await service.post(`/common/sms/retailerPayment`, payload.data);
    console.log(response);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true
    }
    return apiResponse;
}

const getTerms = async (context, payload) => {
    const response = await service.get(`/common/terms/${payload.userType}`);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data;
    }
    return apiResponse;
}

const getUsers = async (context) => {
    const response = await service.get(`/auth/user`);
    let apiResponse = {
        success: false
    }
    if (response.status === 200) {
        apiResponse.success = true
        apiResponse.data = response.data.data;
    }
    return apiResponse;
}


export default {
    loginStep1,
    loginStep2,
    loginStep3,
    logout,
    refreshUser,
    getFarmers,
    getTraderFarmers,
    getFarmer,
    getFarmerPurchases,
    searchFarmerByPhone,
    updateFarmer,
    getStates,
    farmerRegStep1,
    farmerRegStep2,
    farmerRegStepApprove,
    getFarmerSales,
    getRetailer,
    getRetailerSales,
    getRetailerOrderAmount,
    getRetailers,
    retailerRegStep1,
    retailerRegStep2,
    updateRetailer,
    activateRetailer,
    activateTrader,
    activateFarmer,
    getTraders,
    getTrader,
    createTrader,
    updateTrader,
    traderRegStep1,
    traderRegStep2,
    searchTraderByPhone,
    getSettings,
    updateSettings,
    orderStep1,
    orderStep2,
    deleteOrder,
    createPayment,
    getPaymentsByDateRetailer,
    createReceipt,
    getReceiptByDateFarmer,
    updateCreditLimit,
    retailerFarmerSearchStep1,
    retailerFarmerSearchStep2,
    getTransactionMasterByDate,
    downloadTransactionMasterByDate,
    getRetailerPaymentsReceipts,
    sendRetailerPaymentSMS,
    getTerms,
    getRetailersList,
    getUsers,
    userRegStep1,
    userRegStep2,
}
