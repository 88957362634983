<template>
    <div ref="submitPaymentDialog" class="modal fade" data-bs-backdrop="static" id="submitPaymentDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Payment</h5>
                    <button type="button" class="btn-close" @click="closePaymentDialog()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating fs-top mb-3">
                            <input id="amount" type="text" class="form-control" placeholder="amount"
                                v-model="data.paymentAmount" />
                            <label for="amount">Amount</label>
                        </div>
                        <div class="form-floating fs-top mb-3">
                            <datepicker id="paymentDate" class="form-control" v-model="data.paymentDate"
                                :clearable="false" placeholder="Payment Date" format="dd-MM-YYYY" />
                        </div>
                        <div class="form-floating fs-top mb-3">
                            <textarea class="form-control" placeholder="Note" v-model="data.note" id="note"
                                maxlength="500"></textarea>
                            <label for="note">Note</label>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closePaymentDialog()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" @click="submitPayment()">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { mapActions } from 'vuex';
import Datepicker from "vue3-datepicker";

export default {
    name: "PaymentEntryDialog",
    components: {
        Datepicker,
    },
    setup() {

    },
    data() {
        return {
            submitPaymentDialog: null,
            data: {
                paymentAmount: null,
                paymentDate: null,
                note: null
            }
        };
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            required: true
        }
    },
    watch: {
        showHide: function (newVal) {
            if (newVal) {
                this.submitPaymentDialog.show();
            } else {
                this.submitPaymentDialog.hide();
            }
        }
    },
    methods: {
        ...mapActions(['createPayment']),
        async submitPayment() {
            console.log(this.user)
            let success = false;

            this.data.retailerId = this.user.uid
            let response = await this.createPayment(this.data)
            success = response.success
            this.data.paymentAmount = this.data.paymentDate = this.data.note = null

            this.$emit('paymentSubmitted', {
                success
            });
        },
        closePaymentDialog() {
            this.data.paymentAmount = this.data.paymentDate = this.data.note = null
            this.$emit('closed')
        },
    },
    async mounted() {
        this.submitPaymentDialog = new Modal(this.$refs.submitPaymentDialog, {});
        if (this.showHide) {
            this.submitPaymentDialog.show();
        }
    },
}
</script>
<style scoped>

</style>