<template>
    <div ref="updateCreditLimitDialog" class="modal fade" data-bs-backdrop="static" id="updateCreditLimitDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Membership Limit</h5>
                    <button type="button" class="btn-close" @click="close()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating fs-top mb-3">
                            <input id="amount" type="number" class="form-control" placeholder="amount" v-model="data.creditLimit"
                                @input="numericCheck()" />
                            <label for="amount">Membership Limit</label>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="close()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" @click="submit()">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { mapActions } from 'vuex';
import { restrictDecimal } from '@/utils/helpers'

export default {
    name: "UpdateCreditLimitDialog",
    components: {
    },
    setup() {

    },
    data() {
        return {
            updateCreditLimitDialog: null,
            data: {
                creditLimit: null,
            }
        };
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            required: true
        }
    },
    watch: {
        showHide: function (newVal) {
            if (newVal) {
                this.updateCreditLimitDialog.show();
            } else {
                this.updateCreditLimitDialog.hide();
            }
        },
        user: function (val) {
            console.log(val)
            this.data.creditLimit = val.creditLimit
        }
    },
    methods: {
        ...mapActions(['updateCreditLimit']),
        restrictDecimal,
        numericCheck() {
            this.data.creditLimit = restrictDecimal(this.data.creditLimit);
        },
        async submit() {
            console.log(this.user)
            let success = false;

            let response = await this.updateCreditLimit({
                farmerId: this.user.uid,
                data: this.data
            })
            success = response.success
            this.data.creditLimit = null

            this.$emit('creditLimitUpdated', {
                success
            });
        },
        close() {
            this.data.creditLimit = null
            this.$emit('closed')
        },
    },
    async mounted() {
        this.updateCreditLimitDialog = new Modal(this.$refs.updateCreditLimitDialog, {});
        if (this.showHide) {
            this.updateCreditLimitDialog.show();
        }
    },
}
</script>
<style scoped>

</style>