<template>
  <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
    <div class="position-sticky pt-3 sidebar-sticky">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link :to="{ name: 'dashboard' }" class="nav-link">Dashboard</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'farmers' }" class="nav-link">Member
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'retailers' }" class="nav-link">Input Partner
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'traders' }" class="nav-link">Output Partner
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'transactionMaster' }" class="nav-link">Reports
          </router-link>
        </li>
        <li class="nav-item" v-if="userType === 'SUPERADMIN'">
          <router-link :to="{ name: 'usersList' }" class="nav-link">Users
          </router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link :to="{ name: 'payables' }" class="nav-link">Payables
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'receivables' }" class="nav-link">Receivables
          </router-link>
        </li> -->
        <li class="nav-item">
          <router-link :to="{ name: 'settings' }" class="nav-link">Settings
          </router-link>
        </li>
      </ul>
    </div>
  </nav>

</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SideBar',
  computed: {
    ...mapState(["userType"]),
  },
}
</script>

<style scoped lang="scss">
.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 48px 0 0;
  /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

  background-color: rgba(0, 0, 0, .25) !important;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, .70)
    /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #ffffff;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #337ab7;
  font-weight: 700 !important;
  font-size: 18px;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
}
</style>
