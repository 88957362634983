<template>
  <admin-layout>
    <div class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      ">
      <h3 class="h3 heading">
        <slot name="heading" />
      </h3>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <router-link :to="{ name: 'farmerDetails', params: { fid: farmerId } }" class="nav-link"
            exact-active-class="active">
            Details
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'farmerPurchases', params: { fid: farmerId } }" class="nav-link"
            exact-active-class="active">
            Purchases
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'farmerReceipts', params: { fid: farmerId } }" class="nav-link"
            exact-active-class="active">
            Receipts
          </router-link>
        </li>
      </ul>
      <div class="btn-group me-2">
        <button type="button" class="btn btn-sm btn-outline-secondary" @click="editFarmer()" :disabled="!isLoaded()">
          Edit
        </button>
        <button type="button" class="btn btn-sm btn-outline-secondary" @click="showReceiptDialog()" :disabled="!isLoaded()"
          v-if="userType === 'SUPERADMIN'">
          Add Receipt
        </button>
        <button type="button" class="btn btn-sm btn-outline-secondary" @click="showCreditLimitDialog()"
          :disabled="!isLoaded()"  v-if="userType === 'SUPERADMIN'">
          Update Credit Limit
        </button>
        <button v-if="farmer.active" type="button" class="btn btn-sm btn-outline-secondary" @click="deactivate()"
          :disabled="!isLoaded()">
          Deactivate
        </button>
        <button v-if="!farmer.active" type="button" class="btn btn-sm btn-outline-secondary" @click="activate()"
          :disabled="!isLoaded()">
          Activate
        </button>
      </div>
      <edit-farmer-dialog :showHide="editShowHide" :farmer="farmer" @updated="farmerUpdated" @closed="farmerClosed" />
      <active-dialog :showHide="activeShowHide" :userType="'Member'" :user="farmer" @activation="activeResponse"
        @closed="activeClosed" />
      <deactive-dialog :showHide="deactiveShowHide" :userType="'Member'" :user="farmer" @deactivation="deactiveResponse"
        @closed="deactiveClosed" />
      <receipt-entry-dialog :showHide="receiptShowHide" :user="farmer" @receiptSubmitted="receiptResponse"
        @closed="receiptClosed" />
      <update-credit-limit-dialog :showHide="clShowHide" :user="farmer" @creditLimitUpdated="clResponse" @closed="clClosed" />
    </div>
    <slot name="content" :farmerId="farmerId" />
  </admin-layout>
</template>
<script>
import adminLayout from "@/layouts/admin.vue";
import { mapActions, mapState } from 'vuex';
import receiptEntryDialog from '@/components/common/ReceiptEntryDialog.vue'
import activeDialog from '@/components/common/ActivationDialog.vue'
import deactiveDialog from '@/components/common/DeactivationDialog.vue'
import updateCreditLimitDialog from '@/components/common/UpdateCreditLimitDialog.vue'
import { useToast } from "vue-toastification";
import editFarmerDialog from '@/components/common/EditFarmerDialog.vue'

export default {
  name: "FarmerDetailsLayout",
  setup() {
    return {
      toast: useToast()
    }
  },
  data() {
    return {
      farmerId: this.$route.params.fid,
      farmer: {},
      editShowHide: false,
      activeShowHide: false,
      deactiveShowHide: false,
      receiptShowHide: false,
      clShowHide: false
    };
  },
  computed: {
    ...mapState(["userType"]),
  },
  components: {
    adminLayout,
    receiptEntryDialog,
    activeDialog,
    deactiveDialog,
    updateCreditLimitDialog,
    editFarmerDialog,
  },
  async mounted() {
    let response = await this.getFarmer({
      farmerId: this.farmerId
    })
    console.log("====")

    this.farmer = response.data.farmer
    console.log(this.farmer)
  },
  methods: {
    ...mapActions(['getFarmer']),
    isLoaded() {
      return Object.keys(this.farmer).length === 0 ? false : true
    },
    showReceiptDialog() {
      this.receiptShowHide = true
    },
    receiptResponse(response) {
      if (response.success) {
        this.toast.success('Receipt Added');
      }
      this.receiptShowHide = false
    },
    receiptClosed() {
      this.receiptShowHide = false
    },
    deactivate() {
      this.deactiveShowHide = true
    },
    activate() {
      this.activeShowHide = true
    },
    activeClosed() {
      this.activeShowHide = false
    },
    deactiveClosed() {
      this.deactiveShowHide = false
    },
    editFarmer() {
      this.editShowHide = true
      console.log(this.editShowHide)
    },
    farmerUpdated() {
      this.$bus.emit('refreshFarmer', {
        farmerId: this.farmerId
      })
      this.toast.success("Member updation successful");
      this.editShowHide = false
    },
    farmerClosed() {
      this.editShowHide = false
    },
    async activeResponse(response) {
      let msg = response.success ? "Member activated" : "Activation Failed"
      if (response.success) {
        let res = await this.getFarmer({
          farmerId: this.farmerId
        })
        this.farmer = res.data.farmer
        this.$bus.emit('refreshFarmer', {
          farmerId: this.farmerId,
          farmer: this.farmer
        })

        this.toast.success(msg);
      } else {
        this.toast.error(msg);
      }
      this.activeShowHide = false
    },
    async deactiveResponse(response) {
      let msg = response.success ? "Member deactivated" : "Deactivation Failed"
      if (response.success) {
        let res = await this.getFarmer({
          farmerId: this.farmerId
        })
        this.farmer = res.data.farmer;
        this.$bus.emit('refreshFarmer', {
          farmerId: this.farmerId,
          farmer: this.farmer
        });
        this.toast.success(msg);
      } else {
        this.toast.error(msg);
      }
      this.deactiveShowHide = false
    },
    showCreditLimitDialog() {
      this.clShowHide = true
    },
    clResponse(response) {
      this.clShowHide = false
      if (response.success) {
        this.toast.success("Membership Limit Updated");
      }
    },
    clClosed() {
      this.clShowHide = false
    },
    async mounted() {
      let response = await this.getFarmer({
        farmerId: this.farmerId
      })

      if (response.success) {
        this.farmer = response.data.farmer
      }
    },
  }
};
</script>
<style scoped>
.heading {
  width: 250px
}

</style>
