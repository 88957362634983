<template>
  <farmer-details>
    <template #heading>Member Payments</template>
    <template #content>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Amount</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(receipt) in receipts" :key="receipt">
              <td>{{ receipt.receiptDate }}</td>
              <td>{{ receipt.totalAmount }}</td>
              <td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </farmer-details>
</template>

<script>
import farmerDetails from "@/layouts/farmerDetails.vue";
import { mapActions } from "vuex";
import moment from 'moment'

export default {
  name: "FarmerReceiptsView",
  components: {
    farmerDetails,
  },
  data() {
    return {
      farmerId: this.$route.params.fid,
      receipts: []
    };
  },
  computed: {
  },
  methods: {
    ...mapActions(["getReceiptByDateFarmer"]),
  },
  async mounted() {
    let response = await this.getReceiptByDateFarmer({
      farmerId: this.farmerId
    })
    console.log(response)
    this.receipts = response.data.receipts
    this.receipts.forEach(r => {
      r.receiptDate = moment(r.receiptDate).format('DD-MM-YYYY')
    })
  },
};
</script>

<style scoped>
.item-key {
  min-width: 200px;
}

.item-value {
  min-width: 400px;
}
</style>