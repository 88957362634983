<template>
  <trader-layout>
    <div class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      ">
      <h1 class="h2">Members</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <!-- <div class="btn-group me-2">
                            <button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
                            <button type="button" class="btn btn-sm btn-outline-secondary">Export</button>
                        </div>
                        <button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
                            <span data-feather="calendar" class="align-text-bottom"></span>
                            This week
                        </button> -->
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Location</th>
            <th scope="col">Membership Limit Used</th>
            <th scope="col">Membership Limit</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="farmer in farmers" :key="farmer.uid">
            <td>{{ farmer.firstName }} {{ farmer.lastName }}</td>
            <td>{{ farmer.phone }}</td>
            <td>{{ farmer.address }}</td>
            <td>{{ farmer.currentCredit }}</td>
            <td>{{ farmer.creditLimit }}</td>
            <td>{{ getStatus(farmer.step) }}</td>
            <td>
              <router-link class="edit" :to="{
                name: 'farmersTraderDetails',
                params: { 
                  fid: farmer.uid,
                  tid: traderId 
                },
              }">
                <i class="material-icons">&#xE254;</i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item disabled">
            <a class="page-link" href="#">Previous</a>
          </li>
          <li class="page-item active"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">Next</a></li>
        </ul>
      </nav>
    </div>
  </trader-layout>
</template>

<script>
import traderLayout from "@/layouts/trader.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "FarmersView",
  components: {
    traderLayout,
  },
  data() {
    return {
      deactivationDialog: null,
      limit: 10,
      offset: 0,
      traderId: this.$route.params.tid,
    };
  },
  computed: {
    ...mapState(["farmers", "uid"]),
  },
  methods: {
    ...mapActions(["getTraderFarmers"]),
    getStatus(step) {
      if (step === 1) {
        return "Pending OTP Verification"
      } else if (step === 2) {
        return "Pending Approval"
      } else if (step === 3) {
        return "Verified"
      }
    }
  },
  async mounted() {
    await this.getTraderFarmers({
      traderId: this.uid,
      limit: this.limit,
      offset: this.offset,
    });
  },
};
</script>

<style scoped>
table.table td a.edit i {
  font-size: 16px !important;
}

table.table td a.delete i {
  font-size: 16px !important;
}
</style>