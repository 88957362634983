<template>
  <trader-layout>
    <div class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      ">
      <h1 class="h2">Output Partner Dashboard</h1>
      <div class="btn-toolbar mb-2 mb-md-0"></div>
    </div>

    <div class="card text-center mb-3">
      <div class="card-header">Members</div>
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <router-link :to="{ name: 'farmersTrader', params: { tid: uid }}" class="btn btn-primary">View Members
            </router-link>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-primary" @click="addFarmer()">
              Add Member
              </button>
              </div>
              </div>
      </div>
    </div>

    <add-farmer-dialog :traderId="uid" :showHide="showHide" @created="farmerCreated" @closed="farmerClosed">
    </add-farmer-dialog>

  </trader-layout>
</template>

<script>
import traderLayout from "@/layouts/trader.vue";
// import { mapActions, mapState } from "vuex";
import addFarmerDialog from "@/components/common/AddFarmerDialog.vue";
import { mapState } from 'vuex';
import { useToast } from "vue-toastification";


export default {
  name: "TraderDashboardView",
  setup() {
    return {
      toast: useToast()
    }
  },
  components: {
    traderLayout,
    addFarmerDialog
  },
  data() {
    return {
      showHide: false
    };
  },
  computed: {
    ...mapState(["uid"])
  },
  methods: {
    addFarmer() {
      this.showHide = true
    },
    farmerCreated() {
      this.showHide = false;
      this.toast.success("Farmer addition successful")
    },
    farmerClosed() {
      this.showHide = false;
    },
  },

  mounted() {
  },
};
</script>

<style scoped>

</style>