<template>
  <trader-layout>
    <template #heading>Member Details</template>
    <template #content v-if="farmer">
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">First Name</li>
        <li class="list-group-item item-value">{{farmer.firstName}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Last Name</li>
        <li class="list-group-item item-value">{{farmer.lastName}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Phone</li>
        <li class="list-group-item item-value">{{farmer.phone}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Taluka</li>
        <li class="list-group-item item-value">{{farmer.taluka}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">District</li>
        <li class="list-group-item item-value">{{farmer.district}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">State</li>
        <li class="list-group-item item-value">{{farmer.state}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Pincode</li>
        <li class="list-group-item item-value">{{farmer.pincode}}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Credit Used</li>
        <li class="list-group-item item-value">{{farmer.currentCredit}}</li>
      </ul>
    </template>
  </trader-layout>
</template>

<script>
import traderLayout from "@/layouts/trader.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "FarmersTraderDetailsView",
  components: {
    traderLayout,
  },
  data() {
    return {
      farmerId: this.$route.params.fid,
      traderId: this.$route.params.tid,
      farmer: {}
    };
  },
  computed: {
    ...mapState(["farmer"])
  },
  methods: {
    ...mapActions(["getFarmer"]),
  },
  created() {
    console.log(this.farmerId)
  },
  async mounted() {
    const response = await this.getFarmer({
      farmerId: this.farmerId,
      traderId: this.traderId
    })

    if (response.success) {
      this.farmer = response.data.farmer
    }

    console.log(this.farmer)

    // if (response.success) {
    //   this.farmer = response.data.farmer
    // }
  },
};
</script>

<style scoped>
.item-key {
  min-width: 200px;
}

.item-value {
  min-width: 400px;
}
</style>