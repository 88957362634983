<template>
    <div ref="itemsDialog" class="modal fade" data-bs-backdrop="static" id="itemsDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Products</h5>
                    <button type="button" class="btn-close" @click="closeDialog()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Product name</th>
                                    <th scope="col">Pack size</th>
                                    <th scope="col">Quantity (packets/bottles)</th>
                                    <th scope="col">Rate per packet/bottle (GST included)</th>
                                    <th scope="col">Total value ( GST included)</th>
                                    <th scope="col">GST rate applicable</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item) in items" :key="item">
                                    <td>{{item.name}}</td>
                                    <td>{{item.packSize}} {{item.packSizeUnit}}</td>
                                    <td>{{item.quantity}}</td>
                                    <td>{{item.ratePerPack}}</td>
                                    <td>{{item.totalValue}}</td>
                                    <td>{{item.gst}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeDialog()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';

export default {
    name: "ItemDialog",
    setup() {

    },
    data() {
        return {
            itemsDialog: null,
        };
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        },
        items: {
            type: Object,
            required: true
        }
    },
    watch: {
        showHide: function (newVal) {
            if (newVal) {
                this.itemsDialog.show();
            } else {
                this.itemsDialog.hide();
            }
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closed')
        },
        showDeactivationDialog(index) {
            console.log(index);
            this.itemsDialog.show();
        },

    },
    async mounted() {
        this.itemsDialog = new Modal(this.$refs.itemsDialog, {});
        if (this.showHide) {
            this.itemsDialog.show();
        }
    },
}
</script>
<style scoped>

</style>