<template>
  <admin-layout>
    <div class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      ">
      <h1 class="h2">Output Partner</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">
          <button type="button" class="btn btn-sm btn-outline-secondary" @click="addTrader()">
            Add Output Partner
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Location</th>
            <th scope="col">Status</th>
            <th scope="col">OTP Verified</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(trader) in traders" :key="trader.uid">
            <td>{{ trader.index }}</td>
            <td>{{ trader.firstName }} {{ trader.lastName }}</td>
            <td>{{ trader.phone }}</td>
            <td>{{ trader.address }}</td>
            <td>{{ trader.active ? 'Active' : 'Deactive' }}</td>
            <td>{{ trader.step === 2? 'Yes' : 'No'}}</td>
            <td>
              <router-link class="edit" :to="{
                name: 'traderDetails',
                params: { tid: trader.uid },
              }">
                <i class="material-icons">&#xE254;</i>
              </router-link>
            </td>
            </tr>
            </tbody>
            </table>
            </div>
            <div class="mt-4">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item disabled">
                    <a class="page-link" href="#">Previous</a>
                  </li>
                  <li class="page-item active"><a class="page-link" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">Next</a></li>
                </ul>
              </nav>
            </div>
            
            <activation-dialog :userType="'Trader'" :showHide="showActivation" :user="activationUser" />
            <add-trader-dialog :showHide="showHide" @created="traderCreated" @closed="traderClosed" />
            </admin-layout>
</template>

<script>
import adminLayout from "@/layouts/admin.vue";
import { mapActions, mapState } from "vuex";
import activationDialog from '@/components/common/ActivationDialog.vue'
import addTraderDialog from '@/components/common/AddTraderDialog.vue'
import { useToast } from 'vue-toastification';

export default {
  name: "TransactionMasterView",
  components: {
    adminLayout,
    activationDialog,
    addTraderDialog
  },
  setup() {
    return {
      toast: useToast()
    }
  },
  data() {
    return {
      // deactivationDialog: null,
      showActivation: false,
      activationUser: {},
      districts: [],
      talukas: [],
      limit: 10,
      offset: 0,
      showHide: false
    };
  },
  computed: {
    ...mapState(["traders"]),
  },
  methods: {
    ...mapActions(["getTraders", "activateTrader"]),
    showDeactivationDialog(index) {
      console.log("showDeactivationDialog called")
      this.showActivation = !this.showActivation;
      console.log(this.showActivation)
      this.activationUser = this.traders[index];
      console.log(this.activationUser)
    },
    addTrader() {
      this.showHide = true
    },
    async traderCreated() {
      this.showHide = false;
      this.toast.success("Trader addition successful");
      await this.getTraders({
        limit: this.limit,
        offset: this.offset,
      });
    },
    traderClosed() {
      this.showHide = false;
    },
  },
  async mounted() {
    await this.getTraders({
      limit: this.limit,
      offset: this.offset,
    });
  },

};
</script>

<style scoped>
table.table td a.edit i {
  font-size: 16px !important;
}

table.table td a.delete i {
  font-size: 16px !important;
}

.active {
  color: blue !important;
}

.deactive {
  color: red !important;
}
</style>