import DashboardView from '@/views/dashboard/DashboardView.vue'
import RetailerDashboardView from '@/views/dashboard/RetailerDashboardView.vue'
import TraderDashboardView from '@/views/dashboard/TraderDashboardView.vue'
import FarmerDashboardView from '@/views/dashboard/FarmerDashboardView.vue'
import FarmersView from '@/views/farmer/FarmersView.vue'
import TermsConditionsView from '@/views/common/TermsConditions.vue'
import FarmersTraderView from '@/views/farmer/FarmersTraderView.vue'
import FarmersTraderDetailsView from '@/views/farmer/FarmersTraderDetailsView.vue'
import FarmerDetailsView from '@/views/farmer/FarmerDetailsView.vue'
import FarmerPurchasesView from '@/views/farmer/FarmerPurchasesView.vue'
import FarmerReceiptsView from '@/views/farmer/FarmerReceiptsView.vue'
import FarmerTransactions from '@/views/farmer/FarmerTransactions.vue'
import RetailersView from '@/views/retailer/RetailersView.vue'
import RetailerDetailsView from '@/views/retailer/RetailerDetailsView.vue'
import RetailerPaymentsView from '@/views/retailer/RetailerPaymentsView.vue'
import RetailerSalesView from '@/views/retailer/RetailerSalesView.vue'
import RetailerStatement from '@/views/retailer/RetailerStatement.vue'
import RetailerAddFarmerPurchaseView from '@/views/retailer/RetailerAddFarmerPurchaseView.vue'
import RetailerTransactions from '@/views/retailer/RetailerTransactions.vue'
import RetailerInvoice from '@/views/retailer/RetailerInvoice.vue'
import RetailerReports from '@/views/retailer/RetailerReports.vue'
import TradersView from '@/views/trader/TradersView.vue'
import TraderDetailsView from '@/views/trader/TraderDetailsView.vue'
import SettingsView from '@/views/settings/SettingsView.vue'
import PayablesView from '@/views/payable/PayablesView.vue'
import ReceivablesView from '@/views/receivable/ReceivablesView.vue'
import TransactionMasterView from '@/views/reports/TransactionMasterView.vue'
import UsersList from '@/views/user/UsersList.vue'
import { RouterView } from 'vue-router'
import { h } from 'vue'


export default [
    {
        path: '/terms',
        name: 'terms',
        component: TermsConditionsView,
        meta: { auth: true },
    },
    {
        path: '/admin/dash',
        name: 'dashboard',
        component: DashboardView,
        meta: { auth: true },
    },
    {
        path: '/farmer',
        component: { render: () => h(RouterView) },
        children: [
            {
                path: '',
                redirect: { name: 'farmerDashboard' }
            },
            {
                path: 'dash',
                name: 'farmerDashboard',
                component: FarmerDashboardView,
                meta: { auth: true },
            },
            {
                path: ':fid/transactions',
                name: 'farmerTransactions',
                component: FarmerTransactions,
                meta: { auth: true },
            }            
        ]
    },
    {
        path: '/retailer',
        component: { render: () => h(RouterView) },
        children: [
            {
                path: '',
                redirect: { name: 'retailerDashboard' }
            },
            {
                path: 'dash',
                name: 'retailerDashboard',
                component: RetailerDashboardView,
                meta: { auth: true },
            },
            {
                path: ':rid/purchase/add',
                name: 'retailerAddPurchase',
                component: RetailerAddFarmerPurchaseView,
                meta: { auth: true },
            },
            {
                path: ':rid/transactions',
                name: 'retailerTransactions',
                component: RetailerTransactions,
                meta: { auth: true },
            },
            {
                path: ':rid/reports',
                name: 'retailerReports',
                component: RetailerReports,
                meta: { auth: true },
            },
            {
                path: ':rid/invoice',
                name: 'retailerInvoice',
                component: RetailerInvoice,
                meta: { auth: true },
            }            
        ]
    },
    {
        path: '/trader',
        component: { render: () => h(RouterView) },
        children: [
            {
                path: '',
                redirect: { name: 'traderDashboard' }
            },
            {
                path: 'dash',
                name: 'traderDashboard',
                component: TraderDashboardView,
                meta: { auth: true },
            },
            {
                path: ':tid/farmers',
                name: 'farmersTrader',
                component: FarmersTraderView,
                meta: { auth: true },
            },
            {
                path: ':tid/farmers/:fid',
                name: 'farmersTraderDetails',
                component: FarmersTraderDetailsView,
                meta: { auth: true },
            }
        ] 
    },
    {
        path: '/admin/farmers',
        component: { render: () => h(RouterView) },
        children: [
            {
                path: '',
                name: 'farmers',
                component: FarmersView,
                meta: { auth: true },
            },
            {
                path: ':fid',
                name: 'farmerDetails',
                component: FarmerDetailsView,
                meta: { auth: true },
            }, {
                path: ':fid/purchases',
                name: 'farmerPurchases',
                component: FarmerPurchasesView,
                meta: { auth: true },
            }, {
                path: ':fid/receipts',
                name: 'farmerReceipts',
                component: FarmerReceiptsView,
                meta: { auth: true },
            }
        ]
    },
    {
        path: '/admin/retailers',
        component: { render: () => h(RouterView) },
        children: [
            {
                path: '',
                name: 'retailers',
                component: RetailersView,
                meta: { auth: true },
            }, {
                path: ':rid',
                name: 'retailerDetails',
                component: RetailerDetailsView,
                meta: { auth: true },
            }, {
                path: ':rid/payments',
                name: 'retailerPayments',
                component: RetailerPaymentsView,
                meta: { auth: true },
            }, {
                path: ':rid/sales',
                name: 'retailerSales',
                component: RetailerSalesView,
                meta: { auth: true },
            },
            {
                path: ':rid/statement',
                name: 'retailerStatement',
                component: RetailerStatement,
                meta: { auth: true },
            }
        ]
    },
    {
        path: '/admin/traders',
        component: { render: () => h(RouterView) },
        children: [
            {
                path: '',
                name: 'traders',
                component: TradersView,
                meta: { auth: true },
            }, {
                path: ':tid',
                name: 'traderDetails',
                component: TraderDetailsView,
                meta: { auth: true },
            }
        ]
    }, 
    {
        path: '/admin/reports',
        component: { render: () => h(RouterView) },
        children: [
            {
                path: '',
                name: 'transactionMaster',
                component: TransactionMasterView,
                meta: { auth: true },
            }
        ]
    }, 
    {
        path: '/admin/users',
        component: { render: () => h(RouterView) },
        children: [
            {
                path: '',
                name: 'usersList',
                component: UsersList,
                meta: { auth: true },
            }
        ]
    },
    {
        path: '/admin/payables',
        name: 'payables',
        component: PayablesView,
        meta: { auth: true },
    },
    {
        path: '/admin/receivables',
        name: 'receivables',
        component: ReceivablesView,
        meta: { auth: true },
    },
    {
        path: '/admin/settings',
        name: 'settings',
        component: SettingsView,
        meta: { auth: true },
    }
]
