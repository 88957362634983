<template>
  <retailer-layout>
    <div class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      ">
      <h1 class="h2">Input Partner Dashboard</h1>
      <div class="btn-toolbar mb-2 mb-md-0"></div>
    </div>

    <div class="card text-center mb-3">
      <div class="card-header">Orders</div>
      <div class="card-body">
        <p class="card-text">
          View All Orders
        </p>
        <router-link :to="{ name: 'retailerTransactions', params: { rid: uid }}" class="btn btn-primary">Continue
        </router-link>
      </div>
    </div>

    <div class="card text-center mb-3">
      <div class="card-header">New Order</div>

      <div class="card-body">
        <p class="card-text">
          Initiate a new order for Member
        </p>
        <router-link :to="{ name: 'retailerAddPurchase', params: { rid: uid } }" class="btn btn-primary">Create Order
        </router-link>
      </div>
    </div>

    <div class="card text-center mb-3">
      <div class="card-header">Reports</div>
      <div class="card-body">
        <p class="card-text">
          View Account Statement
        </p>
        <router-link :to="{ name: 'retailerReports', params: { rid: uid } }" class="btn btn-primary">View Report
        </router-link>
      </div>
    </div>

    <div class="card text-center mb-3">
      <div class="card-header">Invoices</div>
      <div class="card-body">
        <p class="card-text">
          Submit Invoice
        </p>
        <router-link :to="{ name: 'retailerInvoice', params: { rid: uid } }" class="btn btn-primary">Continue
        </router-link>
      </div>
    </div>

  </retailer-layout>
</template>

<script>
import retailerLayout from "@/layouts/retailer.vue";
import { mapState } from 'vuex';
// import { mapActions, mapState } from "vuex";

export default {
  name: "RetailerDashboardView",
  components: {
    retailerLayout,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["uid"])
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style scoped>

</style>