<template>
  <admin-layout>
    <div class="d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      ">
      <h3 class="h3 heading">
        <slot name="heading" />
      </h3>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <router-link :to="{ name: 'retailerDetails', params: { rid: retailerId } }" class="nav-link"
            exact-active-class="active">
            Details
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'retailerSales', params: { rid: retailerId } }" class="nav-link"
            exact-active-class="active">
            Sales
          </router-link>
        </li>
        <li class="nav-item flex-grow-1">
          <router-link :to="{ name: 'retailerPayments', params: { rid: retailerId } }" class="nav-link"
            exact-active-class="active">
            Payments Made
          </router-link>
        </li>
        <li class="nav-item flex-grow-1">
          <router-link :to="{ name: 'retailerStatement', params: { rid: retailerId } }" class="nav-link"
            exact-active-class="active">
            Statement
          </router-link>
        </li>
      
      </ul>
      <div class="btn-group me-2" :disabled="!isLoaded()">
        <button type="button" class="btn btn-sm btn-outline-secondary" @click="editRetailer()">
          Edit
        </button>
        <button type="button" class="btn btn-sm btn-outline-secondary" @click="showPaymentDialog()"
          v-if="userType === 'SUPERADMIN'">
          Add Payment
        </button>
        <button v-if="retailer.active" type="button" class="btn btn-sm btn-outline-secondary" @click="deactivate()">
          Deactivate
        </button>
        <button v-if="!retailer.active" type="button" class="btn btn-sm btn-outline-secondary" @click="activate()">
          Activate
        </button>
      </div>

      <edit-retailer-dialog :showHide="editShowHide" :retailer="retailer" @updated="retailerUpdated"
        @closed="retailerClosed" />
      <active-dialog :showHide="activeShowHide" :userType="'Partner'" :user="retailer" @activation="activeResponse"
        @closed="activeClosed" />
      <deactive-dialog :showHide="deactiveShowHide" :userType="'Partner'" :user="retailer"
        @deactivation="deactiveResponse" @closed="deactiveClosed" />
      <payment-entry-dialog :showHide="paymentShowHide" :user="retailer" @paymentSubmitted="paymentResponse"
        @closed="paymentClosed" />
    </div>

    <slot name="content" :retailerId="retailerId" />
  </admin-layout>
</template>
<script>
import adminLayout from "@/layouts/admin.vue";
import { mapActions, mapState } from 'vuex';
import editRetailerDialog from '@/components/common/EditRetailerDialog.vue'
import activeDialog from '@/components/common/ActivationDialog.vue'
import deactiveDialog from '@/components/common/DeactivationDialog.vue'
import paymentEntryDialog from '@/components/common/PaymentEntryDialog.vue'
import { useToast } from "vue-toastification";

export default {
  name: "RetailerDetailsLayout",
  setup() {
    return {
      toast: useToast()
    }
  },
  data() {
    return {
      retailerId: this.$route.params.rid,
      retailer: {},
      editShowHide: false,
      activeShowHide: false,
      deactiveShowHide: false,
      paymentShowHide: false,
    };
  },
  components: {
    adminLayout,
    editRetailerDialog,
    activeDialog,
    deactiveDialog,
    paymentEntryDialog
  },
  async mounted() {

    let response = await this.getRetailer({
      retailerId: this.retailerId
    })

    this.retailer = response.data.retailer
    console.log(this.retailer)
  },
  computed: {
    ...mapState(["userType"]),
  },
  methods: {
    ...mapActions(['getRetailer']),
    retailerUpdated() {
      this.$bus.emit('refreshRetailer', {
        retailerId: this.retailerId
      })
      this.toast.success("Input Partner updation successful");
      this.editShowHide = false
    },
    retailerClosed() {
      this.editShowHide = false
    },
    editRetailer() {
      this.editShowHide = true
      console.log(this.editShowHide)
    },
    deactivate() {
      this.deactiveShowHide = true
    },
    activate() {
      this.activeShowHide = true
    },
    showPaymentDialog() {
      this.paymentShowHide = true
    },
    paymentResponse(response) {
      if (response.success) {
        this.toast.success('Payment Added');
      }
      this.paymentShowHide = false
    },
    paymentClosed() {
      this.paymentShowHide = false
    },
    activeClosed() {
      this.activeShowHide = false
    },
    deactiveClosed() {
      this.deactiveShowHide = false
    },
    async activeResponse(response) {
      let msg = response.success ? "Input Partner activated" : "Activation Failed"
      if (response.success) {
        let res = await this.getRetailer({
          retailerId: this.retailerId
        })
        this.retailer = res.data.retailer
        this.$bus.emit('refreshRetailer', {
          retailerId: this.retailerId,
          retailer: this.retailer
        })

        this.toast.success(msg);
      } else {
        this.toast.error(msg);
      }
      this.activeShowHide = false
    },
    async deactiveResponse(response) {
      let msg = response.success ? "Input Partner deactivated" : "Deactivation Failed"
      if (response.success) {
        let res = await this.getRetailer({
          retailerId: this.retailerId
        });
        this.retailer = res.data.retailer;
        this.$bus.emit('refreshRetailer', {
          retailerId: this.retailerId,
          retailer: this.retailer
        });
        this.toast.success(msg);
      } else {
        this.toast.error(msg);
      }
      this.deactiveShowHide = false
    },
    isLoaded() {
      return Object.keys(this.retailer).length === 0 ? false : true
    }
  }
};
</script>
<style scoped>
.heading {
  width: 250px
}
</style>
