<template>
  <base-layout>
    <div id="content-container">
      <div id="login" class="form-signin w-100 m-auto text-center">
        <form>
          <div class="fs-logo-container">
            <span class="fs-logo">FarmServ</span><span class="fs-product-logo">Infinity</span>
          </div>

          <h5 class="h6 mb-3 fw-normal">Login</h5>

          <div class="form-floating">
            <input id="phone" type="text" class="form-control" v-model="phone" maxlength="10" @focus="v$.$reset()"
              :disabled="otpEnabled">
            <label for="phone">Phone Number</label>
            <div v-if="v$.phone.$error" class="error-message mb-2">
              <p v-for="error of v$.phone.$errors" :key="error.$uid">
                <strong>{{ error.$message }}</strong>
              </p>
            </div>
          </div>
          <button id="otpBtn" class="w-100 btn btn-primary" type="button" @click="step1" :disabled="otpButtonDisable">
            Get OTP
          </button>
          <opt-progress-indicator :start="start" @timedOut="timedOut()" />
          <div v-if="otpEnabled">
            <div class="form-floating">
              <input id="otp" type="text" class="form-control" v-model="otp" maxlength="6">
              <label for="otp">OTP</label>
            </div>
            <button id="loginBtn" class="w-100 btn btn-primary" type="button" @click="step2">Login</button>
          </div>
          <p class="mt-5 mb-3 text-muted">Copyright &copy;2022 FarmServ </p>

        </form>
      </div>
    </div>
  </base-layout>
</template>

<script>
import baseLayout from "@/layouts/base.vue";
import optProgressIndicator from "@/components/common/OtpProgressIndicator.vue";
import { mapActions, mapState } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

export default {
  name: "LoginView",
  components: {
    baseLayout,
    optProgressIndicator
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      otpEnabled: false,
      otpButtonDisable: false,
      phone: '',
      otp: '',
      start: false
    };
  },
  computed: {
    ...mapState(['token', 'userType', 'termsAccepted'])
  },
  watch: {
    phone: function (nv, ov) {
      if (nv !== ov) {
        this.otpEnabled = false,
          this.otp = ''
      }
    },
  },
  methods: {
    ...mapActions(['loginStep1', 'loginStep2',]),
    timedOut() {
      this.otpButtonDisable = false;
      this.start = false;
    },
    async step1() {
      const result = await this.v$.$validate()
      if (result) {
        const step1Response = await this.loginStep1({
          phone: this.phone
        })
        if (step1Response.success) {
          this.otpEnabled = true;
          this.otpButtonDisable = true;
          this.start = true
        }
      }
    },
    async step2() {
      const step2Response = await this.loginStep2({
        phone: this.phone,
        otp: this.otp
      })
      console.log(step2Response)
      if (step2Response) {
        console.log(this.token);

        if (this.userType && ['RETAILER', 'TRADER', 'FARMER'].includes(this.userType) && this.termsAccepted === false) {
          this.$router.push({ name: 'terms' });
        } else {
          if (this.userType && this.userType === 'RETAILER') {
            this.$router.push({ name: 'retailerDashboard' });
          } else if (this.userType && this.userType === 'TRADER') {
            this.$router.push({ name: 'traderDashboard' });
          } else if (this.userType && this.userType === 'FARMER') {
            this.$router.push({ name: 'farmerDashboard' });
          } if (this.userType && (this.userType === 'ADMIN') || (this.userType === 'SUPERADMIN')) {
            this.$router.push({ name: 'dashboard' });
          }
        }
      }
    },
  },
  validations() {
    return {
      phone: { required, minLength: minLength(10), $lazy: true }
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
#content-container {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  height: 100vh;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin #phone {
  margin-bottom: 10px;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.form-signin #otp {
  margin-bottom: 10px;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.form-signin #otpBtn {
  margin-bottom: 10px;
}

.fs-logo {
  font-size: 22px;
  font-weight: 400;
  margin-right: 5px;
}

.fs-product-logo {
  font-size: 22px;
  font-weight: 700;
}

.fs-logo-container {
  margin: 20px 0;
}
</style>