<template>
    <div ref="activeDialog" class="modal fade" data-bs-backdrop="static" id="activeDialog"
        aria-labelledby="exampleModalLabel" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Activate {{userType}}</h5>
                    <button type="button" class="btn-close" @click="closeActiveDialog()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure you want to activate this {{userType.toLowerCase()}}?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeActiveDialog()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" @click="activate()">
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { mapActions } from 'vuex';

export default {
    name: "ActivationDialog",
    setup() {

    },
    data() {
        return {
            activeDialog: null,
        };
    },
    props: {
        showHide: {
            type: Boolean,
            default: false
        },
        userType: {
            type: String,
            default: '',
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    watch: {
        showHide: function (newVal) {
            if (newVal) {
                this.activeDialog.show();
            } else {
                this.activeDialog.hide();
            }
        }
    },
    methods: {
        ...mapActions(['activateRetailer', 'activateTrader', 'activateFarmer']),
        async activate() {
            console.log(this.user)
            let success = false;

            let response = {}
            if (this.userType.toLowerCase() === 'distributor') {
                response = await this.activateTrader({
                    data : {state: true},
                    traderId: this.user.uid
                })

            } else if (this.userType.toLowerCase() === 'partner') {
                response = await this.activateRetailer({
                    data : {state: true},
                    retailerId: this.user.uid
                })
            } else {
                response = await this.activateFarmer({
                    data : {state: true},
                    farmerId: this.user.uid
                })  
            }
            success = response.success

            this.$emit('activation', {
                success,
                user: this.user
            });
        },
        closeActiveDialog() {
            this.$emit('closed')
        },
        showDeactivationDialog(index) {
            console.log(index);
            this.activeDialog.show();
        },

    },
    async mounted() {
        this.activeDialog = new Modal(this.$refs.activeDialog, {});
        if (this.showHide) {
            this.activeDialog.show();
        }
    },
}
</script>
<style scoped>

</style>