<template>
  <router-view />
</template>
<script>
import { mapActions } from 'vuex'
import { useToast } from "vue-toastification";
export default {
  setup() {
    return {
      toast: useToast()
    }
  },
  methods: {
    ...mapActions(["refreshUser"])
  },
  async mounted() {
    if (process.env.VUE_APP_MAINTAINENCE_MODE && process.env.VUE_APP_MAINTAINENCE_MODE === 'true') {
      this.$router.push({name: 'maintainence'});
    }

    await this.refreshUser();

    this.$bus.on("errorOccurred", async (data) => {
      console.log(data)
      let errorMessage = null
      if (data.status === 'FAILURE' || data.status === 'ERROR') {
        if (data.errors?.length > 0) {
          errorMessage = data.errors?.map(e => { return e.msg ? e.msg : e + '\n' }).join()
        } else {
          errorMessage = data.msg
        }
      }
      if (errorMessage) this.toast.error(errorMessage);
    });
  }
}
</script>


<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
</style>
