import { createRouter, createWebHistory } from 'vue-router'
import privateRoutes from './privateRoutes'
import publicRoutes from './publicRoutes'
import store from '@/store'

const routes = [...publicRoutes, ...privateRoutes]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition)
    return { top: 0 }
  }
})

// Middlewares
router.beforeEach((to, from, next) => {
  // Redirect to route
  let redirectToRoute = function (name) {
    if (name === from.name) {
      next()
      return
    }

    next({ name: name })
  }

  // Get logged user
  let loggedUser = store.getters.getLoggedUser

  // Check if access token expired
  if (loggedUser) {

    let currentDateTime = new Date().getTime()
    let expiryTime = new Date(loggedUser.exp * 1000)
    if (currentDateTime > expiryTime) {
      store.dispatch('logOut')
      return redirectToRoute('login')
    }
  }

  // Auth
  if (to.meta && to.meta.auth) {
    if (loggedUser)
      return next()
    else
      return redirectToRoute('login')
  }

  next()
})

export default router
