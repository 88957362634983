import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import ToastPlugin from 'vue-toast-notification';
import mitt from 'mitt';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
// import 'vue-toast-notification/dist/theme-sugar.css';
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "bootstrap/dist/css/bootstrap.min.css";
import "vue-toastification/dist/index.css";
import 'nprogress/nprogress.css'
import "./assets/css/common.css";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "nprogress/nprogress.js";

const app = createApp(App)

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        app,
        dsn: "https://3e37181090fb4e2bb20b5a7cfe7ee555@o4504061346578432.ingest.sentry.io/4504061349396480",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "infinity.farmservindia.com", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        logErrors: true
    });
}

app.use(store).use(router).use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 3000
}).use(MonthPicker).use(MonthPickerInput)

const bus = mitt()
app.config.globalProperties.$bus = bus;
app.mount('#app')

store.commit('setBus', bus);


