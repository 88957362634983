<template>
  <trader-details>
    <template #heading>Output Partner Details</template>
    <template #content v-if="trader">
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          First Name
        </li>
        <li class="list-group-item item-value">{{ trader.firstName }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          Last Name
        </li>
        <li class="list-group-item item-value">{{ trader.lastName }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          Entity Name
        </li>
        <li class="list-group-item item-value">{{ trader.entityName }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Phone</li>
        <li class="list-group-item item-value">{{ trader.phone }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">Taluka</li>
        <li class="list-group-item item-value">{{ trader.taluka }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          District
        </li>
        <li class="list-group-item item-value">{{ trader.district }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">State</li>
        <li class="list-group-item item-value">{{ trader.state }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          Pincode
        </li>
        <li class="list-group-item item-value">{{ trader.pincode }}</li>
      </ul>
      <ul class="list-group list-group-horizontal-lg">
        <li class="list-group-item item-key list-group-item-primary">
          Active
        </li>
        <li class="list-group-item item-value">{{ trader.active }}</li>
      </ul>
    </template>

    <!-- Deactivate Retailer Dialog-->
    <div ref="deactivationDialog" class="modal fade" data-bs-backdrop="static" id="deactivationDialog"
      aria-labelledby="exampleModalLabel" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Deactivate Output Partner</h5>
            <button type="button" class="btn-close" @click="closeDeactivationDialog()" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure you want to swicth the active state for this Output Partner?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeDeactivationDialog()">
              Cancel
            </button>
            <button type="button" class="btn btn-primary" @click="deactivateRetailer()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Payment Entry Dialog -->
    <div ref="paymentEntryDialog" class="modal fade" data-bs-backdrop="static" id="paymentEntryDialog"
      aria-labelledby="exampleModalLabel" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Payment made to Output Partner
            </h5>
            <button type="button" class="btn-close" @click="closePaymentDialog()" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-floating fs-top mb-3">
                <input id="amount" type="text" class="form-control" placeholder="amount" v-model="paymentAmount" />
                <label for="amount">Amount</label>
              </div>
              <div class="form-floating fs-top mb-3">
                <div class="input-group" id="datepicker">
                  <datepicker id="paymentDate" class="form-control" v-model="paymentDate" :clearable="false" />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closePaymentDialog()">
              Cancel
            </button>
            <button type="button" class="btn btn-primary" @click="savePayment()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </trader-details>
</template>

<script>
import traderDetails from "@/layouts/traderDetails.vue";
// import { Modal } from "bootstrap";
import Datepicker from "vue3-datepicker";
import { mapActions, mapState } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, numeric, minLength } from '@vuelidate/validators'


const locationSelection = (value) => value !== "NA"

export default {
  name: "TraderDetailsView",
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  components: {
    traderDetails,
    Datepicker,
  },
  data() {
    return {
      paymentAmount: null,
      paymentDate: new Date(),
      // deactivationDialog: null,
      // paymentEntryDialog: null,
      newTrader: {},
      districts: [],
      talukas: [],
      traderId: this.$route.params.tid,
      trader: {},
    };
  },
  validations() {
    return {
      newTrader: {
        firstName: { required },
        lastName: { required },
        address: { required },
        phone: { required, numeric, minLength: minLength(10) },
        pincode: { required, numeric, minLength: minLength(6) },
        entityName: { required },
        district: { locationSelection: helpers.withMessage('Value is required', locationSelection) },
        state: { locationSelection: helpers.withMessage('Value is required', locationSelection) },
        taluka: { locationSelection: helpers.withMessage('Value is required', locationSelection) }
      }
    }
  },
  computed: {
    ...mapState(["states"])
  },
  methods: {
    ...mapActions(["getTrader", "updateTrader", "getStates", "activateTrader"]),
    async deactivateRetailer() {
      let result = await this.activateTrader({
        traderId: this.traderId,
        state: !this.trader.active
      })
      if (result) {
        await this.getTrader({
          traderId: this.traderId
        })
        this.deactivationDialog.hide();
      }
    },
    closeDeactivationDialog() {
      this.deactivationDialog.hide();
    },
    showDeactivationDialog() {
      this.deactivationDialog.show();
    },
    showPaymentDialog() {
      console.log("show Payment Called");
      this.paymentEntryDialog.show();
    },
    savePayment() {
      this.paymentEntryDialog.hide();
    },
    closePaymentDialog() {
      console.log("close Payment Called");
      this.paymentAmount = null;
      this.paymentEntryDialog.hide();
    },
  },
  async mounted() {
    // this.deactivationDialog = new Modal(this.$refs.deactivationDialog, {});
    // this.paymentEntryDialog = new Modal(this.$refs.paymentEntryDialog, {});
    let response = await this.getTrader({
      traderId: this.traderId
    })
    this.trader = response.data.trader

    if (Object.keys(this.states).length === 0) {
      await this.getStates({});
    }

    this.$bus.on("refreshTrader", async (data) => {
      if (this.traderId === data.traderId) {
        if (data.trader) {
          this.trader = data.trader
        } else {
          let response = await this.getTrader({
            traderId: this.traderId
          })
          this.trader = response.data.trader
        }
      }
    });
  },
};
</script>

<style scoped>
.item-key {
  min-width: 200px;
}

.item-value {
  min-width: 400px;
}
</style>