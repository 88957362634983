<template>
    <div class="default">
        <slot />
    </div>
</template>

<script>
export default ({
    name: "BaseLayout",
    data() {
        return {};
    },
    components: {
    },
})
</script>

<style scoped>
.default {
    height: 100%;
}
</style>
