import LoginView from '@/views/common/LoginView.vue'
import MaintainenceView from '@/views/common/MaintainenceView.vue'
import RetailersList from '@/views/common/RetailersList.vue'
import FarmerRegistrationView from '@/views/farmer/FarmerRegistrationView.vue'


export default [
    {
        path: '/',
        redirect: { name: 'login' } 
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {}
    },
    {
        path: '/maintainence',
        name: 'maintainence',
        component: MaintainenceView,
        meta: {}
    },
    {
        path: '/reg',
        name: 'registration',
        component: FarmerRegistrationView,
        meta: {}
    },
    {
        path: '/partner-list',
        name: 'retailersList',
        component: RetailersList,
        meta: {}
    },
]
