<template>
  <farmer-details>
    <template #heading>Report</template>
    <template #content>
      <div class="input-group mb-3">
        <div class="form fs-middle">
          <month-picker-input @change="selectDate" :default-month="currentMonth" :default-year="currentYear" />
        </div>
        <button type="button" class="btn btn-primary" @click="getReport()">
          Get Report
        </button>
      </div>

      <div class="mt-4 mb-4">
        <h6>Opening Orders : {{ openings?.orders }}</h6>
        <h6>Opening Payments: {{ openings?.payments }}</h6>
      </div>

      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Sales </th>
              <th scope="col">Payments</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s) in statement" :key="s">
              <td>{{ formatDate(s.date) }}</td>
              <td>{{ s.orderAmount }}</td>
              <td>{{ s.paymentAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </farmer-details>
</template>

<script>
import farmerDetails from "@/layouts/retailerDetails.vue";
import moment from 'moment'
import { mapActions } from "vuex";
import { MonthPickerInput } from 'vue-month-picker'

export default {
  name: "RetailerStatement",
  components: {
    farmerDetails,
    MonthPickerInput
  },
  data() {
    return {
      statement: [],
      openings: {},
      currentMonth: moment().month() + 1,
      currentYear: moment().year(),
      selectedDate: {},
      retailerId: this.$route.params.rid
    };
  },
  computed: {
  },
  methods: {
    ...mapActions(["getRetailerPaymentsReceipts"]),
    async getReport() {
      this.fetchReport()
    },
    async selectDate(date) {
      this.selectedDate = date;
      console.log(this.selectedDate)
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    async fetchReport() {
      let response = await this.getRetailerPaymentsReceipts({
        retailerId: this.retailerId,
        month: this.selectedDate.monthIndex - 1,
        year: this.selectedDate.year,
      })
      if (response.success) {
        console.log(response.data.payments)
        console.log(response.data.orders)
        this.statement = response.data.records
        this.openings = response.data.openings
      }
    }
  },
  async mounted() {

  },
};
</script>

<style scoped>
.item-key {
  min-width: 200px;
}

.item-value {
  min-width: 400px;
}
</style>