<template>
  <base-layout>
    <div class="container terms-container">
      <div v-html="terms" />
      <div class="row mt-4 mb-4" v-if="terms">
        <div class="col-6 text-center">
          <button type="button" class="btn btn-secondary" @click="decline()">Decline</button>
        </div>
        <div class="col-6 text-center">
          <button type="button" class="btn btn-primary" @click="accept()">Accept</button>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import baseLayout from "@/layouts/base.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "TermsConditionsView",
  components: {
    baseLayout,
  },
  data() {
    return {
      terms: ''
    };
  },
  computed: {
    ...mapState(['token', 'userType', 'termsAccepted'])
  },
  watch: {

  },
  methods: {
    ...mapActions(['getTerms', 'logout', 'loginStep3']),
    async accept() {
      console.log("Calling Terms")
      const response = await this.loginStep3({
        termsAccepted: true
      })
      console.log(response.data)
      if (response.success) {
        if (this.userType && this.userType === 'RETAILER') {
          this.$router.push({ name: 'retailerDashboard' });
        } else if (this.userType && this.userType === 'TRADER') {
          this.$router.push({ name: 'traderDashboard' });
        } else if (this.userType && this.userType === 'FARMER') {
          this.$router.push({ name: 'farmerDashboard' });
        } if (this.userType && (this.userType === 'ADMIN') || (this.userType === 'SUPERADMIN')) {
          this.$router.push({ name: 'dashboard' });
        }
      }
    },
    decline() {
      this.logout();
      this.$router.push({ name: 'login' });
    }
  },
  async mounted() {
    console.log("Calling Terms")
    const response = await this.getTerms({
      userType: this.userType
    })
    console.log(response.data)
    if (response.success) {

      this.terms = response.data.data.terms;
    }
  },
};
</script>

<style scoped>
.terms-container {
  margin-top: 30px;
  padding-bottom: 30px;
  max-width: 700px;
}
</style>