<template>
    <div>
        <div class="progress mb-2" style="height: 2px;" v-if="localStart">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                :style="{ width: timerCount*4 + '%' }" aria-valuenow="0" aria-valuemin="0" aria-valuemax="25">
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "OtpProgressIndicator",
    setup() {

    },
    data() {
        return {
            localStart: false,
            timerCount: 0,
            timer: null,
        };
    },
    props: {
        start: {
            type: Boolean,
            default: false,
            required: true
        },
    },
    watch: {
        start: function (val) {
            console.log(val)
            this.localStart = val
            if (this.localStart) {
                this.startTimer();
            } else {
                this.clearTimer();
            }
        }
    },
    methods: {
        startTimer() {
            this.timerCount = 25
            this.timer = setInterval(() => {
                console.log('timer called ')
                if (this.timerCount === 0) {
                    clearInterval(this.timer)
                    this.localStart = false
                    this.$emit('timedOut')
                } else {
                    this.timerCount--
                }
            }, 1000)
        },
        clearTimer() {
            if (this.timer) {
                clearInterval(this.timer)
                this.localStart = false
            }
        },

    },
    beforeUnmount() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }
}
</script>
<style scoped>

</style>